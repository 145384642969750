import { UPLOAD_FILE, } from "../actionTypes" ;

export const saveFileInit = () => ({
    type: UPLOAD_FILE.INIT,
});

export const saveFileSuccess = payload => ({
    type: UPLOAD_FILE.SUCCESS,
    payload
});

export const saveFileError = payload => ({
    type: UPLOAD_FILE.ERROR,
    payload
});

export const addLocalFileName = payload => ({
    type: UPLOAD_FILE.LOCAL_SOURCE,
    payload
});
