import interview1 from "../assets/images/interview1.png";
import interview2 from "../assets/images/interview2.png";
import interview3 from "../assets/images/interview3.png";
import interview4 from "../assets/images/interview4.png";
import interview5 from "../assets/images/interview5.png";
import interview6 from "../assets/images/interview6.png";
import { ReactComponent as Star } from "../assets/images/star.svg";
import { ReactComponent as DarkStar } from "../assets/images/darkstar.svg";

export const interviewsData = [
  {
    name: "John Smith",
    shortname: "JS",
    date: "12 May, 2019 at 18:18",
    image: interview1,
    organiser: "Savannah Nguyen",
    status: "Accepted",
    rating: [<Star />, <Star />, <Star />, <Star />, <Star />],
    bgColor: "#0693DA"
  },
  {
    name: "Ana Smith",
    shortname: "AS",
    date: "6 March, 2018 at 18:18",
    image: interview2,
    organiser: "Artene McCoy",
    status: "Accepted",
    rating: [<DarkStar />, <DarkStar />, <DarkStar />, <DarkStar />, <DarkStar />],
    bgColor: "#921E95"
  },
  {
    name: "Sam Joe",
    shortname: "SJ",
    date: "14 July, 2015 at 04:18",
    image: interview3,
    organiser: "Albert Flores",
    status: "Rejected",
    rating: [<DarkStar />, <DarkStar />, <DarkStar />, <DarkStar />, <DarkStar />],
    bgColor: "#2E6055"
  },
  {
    name: "Mia Lee",
    shortname: "ML",
    date: "9 Febrary, 2015 at 10:32",
    image: interview4,
    organiser: "Albert Flores",
    status: "Accepted",
    rating: [<Star />, <Star />, <Star />, <Star />, <DarkStar />],
    bgColor: "#EC7600"
  },
  {
    name: "Edward Doe",
    shortname: "ED",
    date: "9 September, 2013 at 10:41",
    image: interview5,
    organiser: "John Smith",
    status: "To review",
    rating: [<DarkStar />, <DarkStar />, <DarkStar />, <DarkStar />, <DarkStar />],
    bgColor: "#0693DA"
  },
  {
    name: "Jude Inno",
    shortname: "JI",
    date: "11 Febrary, 2012 at 09:01",
    image: interview6,
    organiser: "Country Henry",
    status: "To review",
    rating: [<DarkStar />, <DarkStar />, <DarkStar />, <DarkStar />, <DarkStar />],
    bgColor: "#F67951"
  },
];
