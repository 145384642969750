import React, { useEffect, useState } from "react";
import RecordTabs from "../VideoRecords/RecordTabs";
import styles from "./style.module.scss";

let audio = null;
let chunks = [];
let recorder = null;
let mixedStream = null;

function VideoRecorderComponent({ item, exit, setTempVideo }) {

  const { text, _id } = item || {};
  let canceled = false;
  const [recording, setRecording] = useState(false);
  const [canRecord, setCanRecord] = useState(true);
  const [error, setError] = useState(false);
  const [paused, setPaused] = useState(false);
  const [startCounter, setStartCounter] = useState(null);
  const [time, setTime] = useState({
    minute: "00",
    second: "00",
  });

  const [audioActive, setAudioActive] = useState(true);

  const initializeRecording = () => {
    setCanRecord(false);
    let timer;
    setStartCounter(3);
    timer = setInterval(
      () =>
        setStartCounter((prev) => {
          if (prev === 1) {
            clearInterval(timer);
            startRecording();
            setCanRecord(true);
            return null;
          }
          return prev - 1;
        }),
      1000
    );
  };

  async function setupStream() {
    try {
      audio = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          sampleRate: 44100,
        },
        video: true,
      });
      mixedStream = new MediaStream([...audio.getTracks()]);
    } catch (err) {
      setError(err);
      throw new Error(err);
    }
  }

  useEffect(() => {
    setupStream().then(() => document.getElementById("video").srcObject = mixedStream ? mixedStream : "");
  }, []);

  function muteAndUnmute() {
    const track = audio.getAudioTracks();
    track[0].enabled = !audioActive;
    setAudioActive(!audioActive);
  }

  async function startRecording() {
    recorder = new MediaRecorder(mixedStream);
    recorder.start();
    recorder.ondataavailable = handleDataAvailable;
    recorder.onstop = handleStop;
    setRecording(true);
    console.log("Recording started");
  }


  function pauseAndResumeRecording() {
    if (!recorder) return;
    if (recorder.state === "recording") {
      recorder.pause();
      setPaused(true);
    } else if (recorder.state === "paused") {
      recorder.resume();
      setPaused(false);
    }
  }

  function handleDataAvailable(e) {
    chunks.push(e.data);
  }

  const handleStopRecording = () => {
    recorder.stop();
  }

  const handleStop = () => {
      if(!canceled) {
        const blob = new Blob(chunks, { type: "video/webm" });
        chunks = [];
        let downloadButton = null;
        downloadButton = URL.createObjectURL(blob);
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          let base64data = reader.result;
          item && _id ? setTempVideo((prev) => {
            return {
              ...prev,
              [_id]: {
                questionId: _id,
                video_url: base64data,
              },
            };
          }) : setTempVideo(base64data);
        };
        audio?.getTracks().forEach((track) => track.stop());
        setRecording(false);
        exit();
      } else {
        return;
      }
  }

  useEffect(() => {
    document.querySelector("*").classList.add(`${styles.noScroll}`);

    return () => {
      document.querySelector("*").classList.remove(`${styles.noScroll}`);
    };
  }, []);

  const handleExiting = () => {
    canceled = true;
    audio && audio.getTracks().forEach((track) => track.stop());
    exit();
  };

  return (
    <div className={styles.videoRecorder} onClick={() => handleExiting()}>
      <div
        className={styles.recorderContent}
        onClick={(e) => e.stopPropagation()}
      >
        {text && <h2 className={styles.questionText}>{text}</h2>}
        {startCounter && <p className={styles.counter}>{startCounter}</p>}
        <div className={styles.videoContainer}>
          <video id="video" className={styles.video} autoPlay muted></video>
        </div>
        <div className={styles.recordingBtnsContainer}>
          {recording ? (
            <RecordTabs
              stopRecording={(minute, second) => {
                setTime({ minute: minute, second: second });
              }}
              finishRecording={handleStopRecording}
              recording={recording}
              pauseAndResumeRecording={pauseAndResumeRecording}
              paused={paused}
              audio={muteAndUnmute}
              audioSettings={audio}
              audioActive={audioActive}
              cancelRecording={handleExiting}
              noStyle={true}
            />
          ) : (
            <button
              onClick={canRecord ? initializeRecording : null}
              className={styles.recordingBtn}
            >
              REC
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default VideoRecorderComponent;
