// import uuidv4 from 'uuid/v4';
import InterviewPanel from "../../components/hocs/InterviewPanel";
import CompanyInfo from "../../components/ui/organisms/companyInfo";
import GetStarted from "../../components/ui/organisms/getStarted";
import GetStartedWithoutVideo from "../../components/ui/organisms/getStartedWithoutVideo";
import SetUpInformation from "../../components/ui/molecules/SetUpInformation";
import MyVideoDashboard from "../../components/ui/organisms/myVideoDashboard";
import DonePopup from "../../components/ui/organisms/donePopup";
import InterviewSteps from "../../components/ui/molecules/InterviewSteps";
import ProtectedPanel from "../../components/hocs/ProtectedPanel.js";
import Applicant from "../../components/ui/molecules/Applicant"
import AllApplicants from "../../components/ui/organisms/allApplicants";
import JobPositions from "../../components/ui/organisms/allJobs";

const appRoutes = [
  {
		pathname: '/interviewsteps',
		exact: true,
		element: InterviewSteps,
		layout: ProtectedPanel,
		id: 7
	},
  {
    pathname: "/applicant",
    exact: true,
    element: Applicant,
    layout: ProtectedPanel,
    id: 9
  },
  {
		pathname: '/all-applicants',
		exact: true,
		element: AllApplicants,
		layout: ProtectedPanel,
		id: 10
	},
  {
		pathname: '/share/job/:id',
		exact: true,
		element: CompanyInfo,
		layout: InterviewPanel,
		id: 11
	},
	{
		pathname: '/company-info/:id',
		exact: true,
		element: CompanyInfo,
		layout: InterviewPanel,
		id: 1
	},
	{
        pathname: "/get-started/:id",
        exact: true,
        layout: InterviewPanel,
        element: GetStarted,
		id: 2
    },
	{
        pathname: "/get-started-without-video",
        exact: true,
        layout: InterviewPanel,
        element: GetStartedWithoutVideo,
		id: 3
    },
	{
        pathname: "/set-your-information/:id",
        exact: true,
        layout: InterviewPanel,
        element: SetUpInformation,
		id: 4
    },
	{
        pathname: "/my-video/:id/:submissionId",
        exact: true,
        layout: InterviewPanel,
        element: MyVideoDashboard,
		id: 5
    },
	{
        pathname: "/done-popup",
        exact: true,
        layout: InterviewPanel,
        element: DonePopup,
		id: 6
    },
	{
		pathname: '/all-jobs',
		exact: true,
		element: JobPositions,
		layout: ProtectedPanel,
		id: 12
	},
];

export default appRoutes;
