const mode = process.env.NODE_ENV;
let data = {}
switch (mode) {
    case 'staging':
        data = {
            API_URL: process.env.REACT_APP_API_URL || 'http://localhost:4000',
        }
        break;
    default:
        data = {
            API_URL: process.env.REACT_APP_API_URL || 'http://localhost:4000',
            
        }
        break;
}

export default data;