import { combineReducers } from "redux";
import jobReducer from "./reducers/job.reducers";
import questionsReducer from "./reducers/Questions.reducers";
import recordingReducer from "./reducers/recordingReducer";
import sharesReducer from "./reducers/sharesReducer";
import commentsReducer from "./reducers/commentsReducer";
import userReducer from "./reducers/userReducer";
import jobResponseReducer from "./reducers/job.response.reducer";
import fileReducer from "./reducers/fileReducer";

const rootReducer = combineReducers({
  job: jobReducer,
  questions: questionsReducer,
  recording: recordingReducer,
  shares: sharesReducer,
  comments: commentsReducer,
  user: userReducer,
  jobResponses: jobResponseReducer,
  fileUpload: fileReducer,
});

export default rootReducer;
