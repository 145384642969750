import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./style.module.scss";
import mw from "../../../../assets/images/mw.png";
import Form from "../../molecules/Form";
import OptionInput from "../../atoms/OptionInput";
import SharedButton from "../../atoms/SharedButton";
import { ReactComponent as Upload } from "../../../../assets/images/upload.svg";
import DashboardNavigation from "../DashboardNavigation";
import { getShareJobData } from "../../../../redux/actions/sharesActions";
import { saveJobResponse } from "../../../../redux/actions/jobResponseActions";
import { errorToast } from "../../atoms/Toasts/toasts";
import { isKeyValueNull, truncateString } from "../../../../utils/helpers";
import { saveFile } from "../../../../redux/actions/fileUpload";
import Loader from "../../atoms/Loader";

const RoomsAvailable = ["Yes", "No"];

function SetUpInformation() {
  const [formError, setFormError] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const { loading: fileUploadLoading, data } = useSelector(
    (state) => state.fileUpload
  );
  const { loading: jobResponseLoading } = useSelector(
    (_state) => _state.jobResponses
  );
  const shareId = useParams().id;

  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    if (shareId !== "") {
      dispatch(getShareJobData(shareId));
      //   setActiveVideo(jobData?.questions?.[0])
    }
  }, [shareId]);

  const [values, setValues] = useState({
    full_name: "",
    email: "",
    telephone: "",
    personalInfo: "",
    location: "",
  });

  const nextPage = (id) => {
    return navigate(`/my-video/${shareId}/${id}`);
  };

  const submitUserInfo = () => {
    if (isKeyValueNull(values)) {
      setFormError("All fields are required!");
      return;
    } else {
      if (selectedFile) {
        uploadDocument(saveResponseCallBack);
      } else {
        dispatch(saveJobResponse(values, nextPage));
      }
    }
  };
  const uploadDocument = (callBack) => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    dispatch(saveFile(formData, callBack));
  };

  const saveResponseCallBack = (resume_url) => {
    dispatch(saveJobResponse(values, nextPage, resume_url));
  };

  return (
    <div className={styles.setInfo}>
      <div
        className={styles.leftImg}
        style={{ backgroundImage: `url(${mw})` }}
      />
      <div className={styles.info}>
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <h1>Set up your Information</h1>
            <div className={styles.inputsUpload}>
              <div className={styles.inputs}>
                <Form
                  setValues={setValues}
                  formValues={values}
                  text="Leave your personal information"
                />
              </div>
              <div className={styles.uploadResume}>
                <label>
                  <input
                    type="file"
                    class="hidden"
                    name="fileUpload"
                    onChange={(e) => setSelectedFile(e.target.files[0])}
                  />
                  <Upload />
                </label>
                <p>
                  {" "}
                  {selectedFile === null
                    ? "Upload Resume"
                    : truncateString(selectedFile?.name)}
                </p>
              </div>
            </div>
            <div className={styles.rightContent}>
              <p className={styles.paragraph}>Show Room Available</p>
              <OptionInput list={RoomsAvailable} extraClass="information" />
            </div>
            {formError && <p className={styles.paragraph}>{formError}</p>}
            {!jobResponseLoading ? (
              <SharedButton
                children={
                  fileUploadLoading ? "Uploading document" : "Save & Continue"
                }
                clickEvent={submitUserInfo}
              />
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default SetUpInformation;
SetUpInformation.propTypes = {
  title: PropTypes.string,
  objectives: PropTypes.array,
  responsibilities: PropTypes.array,
  skills: PropTypes.array,
  hasVideo: PropTypes.bool,
};
