/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./style.module.scss";
import PropTypes from "prop-types";
import { ReactComponent as Person } from "../../../../assets/images/person.svg";
import { ReactComponent as Duplicate } from "../../../../assets/images/duplicate.svg";
import TwoButtons from "../../atoms/TwoButtons";
import { ReactComponent as ArrowDown } from "../../../../assets/images/arrowdown.svg";
import { ReactComponent as ArrowUp } from "../../../../assets/images/arrowup.svg";
import { postJob } from "../../../../api/job";
import { getShares } from "../../../../redux/actions/sharesActions";
import { authenticateUser } from "../../../../utils/api/authHandler";
import { addUser } from "../../../../redux/actions/user.actions";

function InterviewSetup3(props) {
  const [copySuccess, setCopySuccess] = useState("");
  const textAreaRef = useRef(null);

  const copyToClipboard1 = e => {
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    setCopySuccess("Copied!");
  };

  const [interview, setInterview] = useState(false);
  const [interviewoption, setInterviewOption] = useState("No end date");
  const interviewDate = ['10 days', '1 month'];

  const jobData = useSelector(state => state.job);
  const jobLink = useSelector(state => state.job.jobLink);
  const userEmail = useSelector(state => state.user.email);
  // const expiryOptions = useSelector(state => state.shares.shares);
  const jobShareLink = useSelector(state => state.shares.shares.shareLink);

  const data = {
    description: jobData.description,
    title: jobData.jobTitle,
    intro_video_url: jobData.videoUrl
  }

  const handlePublish = () => {
    window.location.href = 'all-jobs'
  }

  const backStep = () => {
    props?.handleBack()
  }

  const sessionData = authenticateUser()
  const wizr_user_id = useSelector(state => state.user.wizr_user_id);
  useEffect(() => {
    if(wizr_user_id === '') {
      sessionData[0] && dispatch(addUser(sessionData[1]))
    }
  })

  // const setInterviewDates= () => {
  //   expiryOptions && expiryOptions.forEach(element => {
  //     interviewDate.push(element.expiry)
  //   });
  // }

  const dispatch = useDispatch();
  
  useEffect(() => {
    
    if(!jobShareLink){
      dispatch(getShares(jobData?.jobId));
    }
  });
  // setInterviewDates();

  return (
    <div className={styles.interviewSetup2}>
      <div>
        <h1>Make ready to share link</h1>
        <div>
          <p>Live until</p>
          <div
            aria-hidden={true}
            role="button"
            className={styles.selectOptions}
            onClick={() => setInterview(!interview)}
          >
            <div className={styles.title}>
              {interview ? (
                <ArrowDown className={styles.arrow} />
              ) : (
                <ArrowUp className={styles.arrow} />
              )}
              <p>{interviewoption}</p>
            </div>
            {interview && (
              <div className={styles.dropdown}>
                {interviewDate.map((item, i) => {
                  return (
                    <p key={i} onClick={() => setInterviewOption(item)}>
                      No end date&nbsp;{item}
                    </p>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div>
          <p>Share with others</p>
          <div className={styles.inputIcon}>
            <input
              type="text"
              name="URL video linkdsdsds"
              ref={textAreaRef}
              value={jobShareLink} readOnly
            />
            {document.queryCommandSupported("copy") && (
              <>
                {copySuccess ? (
                  <p className={styles.success}>{copySuccess}</p>
                ) : (
                  <Duplicate onClick={copyToClipboard1} />
                )}
              </>
            )}
          </div>
        </div>
        <div className={styles.owner}>
          <Person />
          <p>{userEmail}</p>
          <p>(Owner)</p>
        </div>
        <h4>Almost done! Click ‘’Share’’ to save form</h4>
        <p className={styles.info}>
          You can edit this information at any time. Canditates can be invited
          on the next page. If any questions please contact us.
        </p>
      </div>
      <div className={styles.buttons}>
        <TwoButtons
          firsttext="Back"
          secondtext="Publish"
          extraClass="lightGrayBtn"
          secondBtnLink="/company-info"
          handleSecondButtonClick={handlePublish}
          handleFirstButtonClick={()=>backStep()}
        />
      </div>
    </div>
  );
}

export default InterviewSetup3;
InterviewSetup3.propTypes = {
  searchData: PropTypes.array,
  setOpenDashboard: PropTypes.func,
  invite: PropTypes.bool,
  text: PropTypes.string,
};
