import {
    getSharesRequest,
    getSharesSuccess,
    getSharesError,
	getJobSharesSuccess,
} from "./actionCreators/sharesActionCreators";
import requestServer from "../../utils/api";

export const getShares = (jobId) => async dispatch => {
  	dispatch(getSharesRequest());
	const date = new Date()
	let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
	try {
		const result = await requestServer(
			'POST',
			'share',
			'/createShareLink', 
			'',
			// Add expiry date too
			{job_id:  jobId, expiry: lastDay}
		);
		dispatch(getSharesSuccess(result.data.data));
	} catch (error) {
		dispatch(getSharesError(error));
	}
};

export const getShareJobData = (shareId) => async dispatch => {
	dispatch(getSharesRequest());
	try {
	  const result = await requestServer(
		  'GET',
		  'share/getShare',
		  `/${shareId}`, 
		  '',
	  );
	  dispatch(getJobSharesSuccess(result.data.data?.share));
  } catch (error) {
	 console.log(error)
  }
};