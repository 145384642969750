import React from "react";
import { Link } from "react-router-dom";
import DashboardCard from "../../atoms/DashboardCard";
import styles from "./style.module.scss";
import SharedButton from "../SharedButton";

export default function DashboardCards() {
  return (
    <div className={styles.cards}>
      <DashboardCard title="Active interviews" subtitle="4" linkTo="/all-applicants" />
      <DashboardCard
        title="Respond rate"
        subtitle="100%"
        extraClass="active"
      />
      <DashboardCard title="Upgrade plan" subtitle="Free" />
      <DashboardCard title="More questions?" subtitle="Contact" />
      <div className={styles.buttonsContainer}>
        <Link to="/interviewsteps">
          <SharedButton>Create Job Post</SharedButton>
        </Link>
        <Link to="/all-jobs" className={styles.link}>
          <SharedButton extraClass="grayBtn1" >All Positions</SharedButton>
        </Link>
      </div>
    </div>
  );
}
