import { SAVE_JOB_RESPONSE, UPDATE_JOB_RESPONSE, GET_JOB_RESPONSE } from "../actionTypes" ;

export const saveJobResponseInit = () => ({
    type: SAVE_JOB_RESPONSE.INIT,
});

export const saveJobResponseSuccess = payload => ({
    type: SAVE_JOB_RESPONSE.SUCCESS,
    payload
});

export const saveJobResponseError = payload => ({
    type: SAVE_JOB_RESPONSE.ERROR,
    payload
});

export const updateJobResponseInit = () => ({
    type: UPDATE_JOB_RESPONSE.INIT,
});

export const updateJobResponseSuccess = payload => ({
    type: UPDATE_JOB_RESPONSE.SUCCESS,
    payload
});

export const updateJobResponseError = payload => ({
    type: UPDATE_JOB_RESPONSE.SUCCESS,
    payload
});

export const getJobResponseInit = () => ({
    type: GET_JOB_RESPONSE.INIT,
});

export const getJobResponseSuccess = payload => ({
    type: GET_JOB_RESPONSE.SUCCESS,
    payload
});

export const getJobResponseError = payload => ({
    type: GET_JOB_RESPONSE.ERROR,
    payload
});

