import requestServer from "../../utils/api/index";
import { USER } from "../actions/actionTypes";

export const setSavedUser = payload => ({
    type: USER.ADD,
    payload,
  });

export const addUser = (body, video) => async dispatch => {
    try {
        const response = await requestServer('POST', '/user', `/addUser`, '', body);
        dispatch(setSavedUser(response.data.user));
    } catch (error) {
        console.log(error);
    }
};