import defineAction from "./utils";

export const RECORDING = defineAction("RECORDING", [
  "INIT",
  "SUCCESS",
  "ERROR",
  "SETVIDEOURL",
]);
export const SHARES = defineAction("SHARES", [
  "REQUEST",
  "SUCCESS",
  "ERROR",
  "SHARE_SUCCESS",
  "CREATE_USER_INFO_REQUEST",
]);
export const USER = defineAction("ADD", ["ADD", "SUCCESS", "ERROR"]);
export const COMMENTS = defineAction("COMMENTS", [
  "FETCH_ALL_COMMENTS_REQUEST",
  "FETCH_ALL_COMMENTS_SUCCESS",
  "FETCH_ALL_COMMENTS_ERROR",
  "ADD_COMMENT_REQUEST",
  "ADD_COMMENT_SUCCESS",
  "ADD_COMMENT_ERROR",
  "DELETE_COMMENT_REQUEST",
  "DELETE_COMMENT_SUCCESS",
  "DELETE_COMMENT_ERROR",
  "EDIT_COMMENT_REQUEST",
  "EDIT_COMMENT_SUCCESS",
  "EDIT_COMMENT_ERROR",
]);
export const JOBS = defineAction("JOBS", [
  "FETCH_ALL_JOBS_REQUEST",
  "FETCH_ALL_JOBS_SUCCESS",
  "FETCH_ALL_JOBS_ERROR",
]);
export const SAVE_JOB_RESPONSE = defineAction("SAVE_JOB_RESPONSE", [
  "INIT",
  "SUCCESS",
  "ERROR",
]);
export const UPDATE_JOB_RESPONSE = defineAction("UPDATE_JOB_RESPONSE", [
  "INIT",
  "SUCCESS",
  "ERROR",
]);
export const GET_JOB_RESPONSE = defineAction("GET_JOB_RESPONSE", [
  "INIT",
  "SUCCESS",
  "ERROR",
]);
export const UPLOAD_FILE = defineAction("UPLOAD_FILE", [
  "INIT",
  "ERROR",
  "SUCCESS",
  "LOCAL_SOURCE"
]);
