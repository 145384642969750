import React, { useState, useEffect }  from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./style.module.scss";
import PropTypes from "prop-types";
import Input from "../../atoms/SingleInput";
import { setUpUserInfo } from "../../../../redux/actions/sharesActions";

function useForm(initialState = {}){
    const [values, setValues] = useState(initialState);
  
    const changeHandler = e => {
      e.preventDefault();
      const newValues = {...values, [e.target.name] : e.target.value};
      setValues(newValues);
    }
  
    return {values, changeHandler};
  }


function isNullEmptyBlank (str = ""){
    return str === null || str.trim().length === 0;
  }


function SharedButton({ text, formValues, setValues }) {
    const changeHandler = e => {
        e.preventDefault();
        const newValues = {...formValues, [e.target.name] : e.target.value};
        setValues(newValues);
    }
    return (
        <>
     
        <Input 
            required={true} 
            placeholder="Full Name"  
            name='full_name' 
            value={formValues.full_name} 
            handleChange={changeHandler} 
            // errorMsg={!isFormValid && isNullEmptyBlank(formValues.fullName) ? 'Can not be blank' : ''}
        />

            <Input 
                placeholder="Email"
                required={true} 
                value={formValues.email} 
                name='email' 
                handleChange={changeHandler} 
                // errorMsg={!isFormValid && isNullEmptyBlank(formValues.email) ? 'Can not be blank' : ''}
                 />
            <Input   
                placeholder="Phone"
                type="tel"
                required={true} 
                value={formValues.telephone} 
                id="phone"
                name='telephone' 
                handleChange={changeHandler} 
                // errorMsg={!isFormValid && isNullEmptyBlank(formValues.phone) ? 'Can not be blank' : ''} 
                />
            <Input 
                placeholder="Location" 
                type="text" 
                value={formValues.location} 
                name='location' 
                handleChange={changeHandler}
                // errorMsg={!isFormValid && isNullEmptyBlank(formValues.location) ? 'Can not be blank' : ''} 
                />
            <textarea className={styles.personalInfo} rows="8" value={formValues.personalInfo} 
                name='personalInfo' onChange={changeHandler}>
                { text }
            </textarea>
        </>
    );
}

export default SharedButton;
SharedButton.propTypes = {
    text: PropTypes.string,
};
