import React, { useEffect } from "react";
import styles from "./style.module.scss";
import { ReactComponent as Logo } from "../../../../assets/images/logo.svg";
import profile from "../../../../assets/images/profile-picture.webp";
import SharedButton from "../../atoms/SharedButton";
import PropTypes from "prop-types";
import { authenticateUser } from "../../../../utils/api/authHandler";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { addUser } from "../../../../redux/actions/user.actions";

const frontendURl = process.env.REACT_APP_WiZR_INTERVIEW_URL || 'http://localhost:3000'
const loginURl = process.env.REACT_APP_LOGIN_URL || 'http://studio.mwsteamlocal.com:3001'
function TopNavigation({ title, interview }) {

  const dispatch = useDispatch();
  const {isAuthenticated} = useSelector(state => state.user)

  useEffect(() => {
    const token = Cookies.get("authCode");
    if(token) {
      const sessionData = authenticateUser()
      sessionData[0] && dispatch(addUser(sessionData[1]))
    }
  }, [])
  return (
    <div className={styles.topNavigation}>
      {interview === true ?
        <>
          <div className={styles.logoTitle}>
            <div className={styles.logo} onClick={() => window.location.href='https://wizrconnect.com/'}>
              <Logo />
              <h2>
                WiZR <span>CONNECT</span>
              </h2>
            </div>
              <p>Interview - <span>{title}</span></p>
          </div>
          {/* <div className={styles.info}>
            <SharedButton clickEvent={() => window.location.href=`${loginURl}/free-trial-log-in?_rdr=${frontendURl}/company-info`} children="Login" />
            <SharedButton clickEvent={() => window.location.href=`${loginURl}/free-trial-sign-up?_rdr=${frontendURl}/company-info`} children="Sign Up" />
          </div> */}
        </>
        :
        <>
          <div className={styles.logo}>
            <Logo />
            <h2>
              WiZR <span>CONNECT</span>
            </h2>
          </div>
          <div className={styles.info}>
            { isAuthenticated
              ?
              (<div className={styles.plan}>
                <p>
                  <span>7/13</span> Upgrade plan
                </p>
                <img src={profile} alt="profile" />
              </div>) 
              :
              (
              <>
                <SharedButton clickEvent={() => window.location.href=`${loginURl}/free-trial-log-in?_rdr=${frontendURl}/dashboard`} children="Login" />
                <SharedButton clickEvent={() => window.location.href=`${loginURl}/free-trial-sign-up?_rdr=${frontendURl}/dashboard`} children="Sign Up" />
              </>
              )
            }
          </div>
        </>
      }
    </div>
  );
}

export default TopNavigation;
TopNavigation.propTypes = {
  title: PropTypes.string,
  interview: PropTypes.bool,
};

