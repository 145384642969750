export const isKeyValueNull = (object) => {
  for (const property in object) {
    if (object[property] == "" || object[property] == null) {
      return true;
    }
    return false;
  }
};

export const truncateString = (initial) => {
  var split = initial.split(".");
  var filename = split[0];
  var extension = split[1];
  if (filename.length > 10) {
    filename = filename.substring(0, 10);
  }
  return filename + "." + extension;
};
