import React from "react";
import { Link } from "react-router-dom";
import SharedButton from "../SharedButton";
import styles from "./style.module.scss";
import PropTypes from "prop-types";

function TwoButtons({ firsttext, secondtext, extraClass, firstBtnLink, handleFirstButtonClick, handleSecondButtonClick }) {
  return (
    <div className={styles.twoButtons}>
      <Link to={firstBtnLink || ''}>
        <SharedButton extraClass={extraClass} clickEvent={handleFirstButtonClick}>{firsttext}</SharedButton>
      </Link>
        <SharedButton clickEvent={handleSecondButtonClick}>{secondtext}</SharedButton>
    </div>
  );
}

export default TwoButtons;
TwoButtons.propTypes = {
  firsttext: PropTypes.string,
  secondtext: PropTypes.string,
  extraClass: PropTypes.string,
  handleSecondButtonClick: PropTypes.func,
  handleFirstButtonClick: PropTypes.func,
};
