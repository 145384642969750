import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./style.module.scss";
import { ReactComponent as First } from "../../../../assets/images/first.svg";
import { ReactComponent as Second } from "../../../../assets/images/second.svg";
import { ReactComponent as Third } from "../../../../assets/images/third.svg";
import InterviewSetup1 from "../InterviewSetup1";
import InterviewSetup2 from "../InterviewSetup2";
import InterviewSetup3 from "../InterviewSetup3";
import {
  checkFormValidity,
  postJobtoDB,
} from "../../../../redux/actions/job.actions";
import { saveVideo } from "../../../../redux/actions/fileUpload";
import { createJobReducer } from "../../../../redux/actions/job.actions";
import { errorToast } from "../../atoms/Toasts/toasts";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export default function InterviewSteps() {
  const [index, setIndex] = useState(0);
  const dispatch = useDispatch();
  const jobData = useSelector((state) => state.job);
  const loading = useSelector((state) => state.fileUpload.loading)

  const validField = (value) => value !== null && value.trim().length > 0;

  const handleClickQuestionsBtn = (data, video, videoUrl) => {

    const { jobTitle, companyName, description } = data;
    if (
      validField(jobTitle) &&
      validField(companyName) &&
      validField(description) &&
      validField(video)
    ) {
      dispatch({ type: checkFormValidity(), payload: true });
      dispatch({ type: createJobReducer(), payload: data });
      if (!jobData.jobId) {
        dispatch(postJobtoDB(data, video, videoUrl, setIndex));
        
      } else setIndex(1);
    } else {
      dispatch({ type: checkFormValidity(), payload: false });
      errorToast("No field should be left blank");
    }
  };

  const handleNext = (data, video, dataURL) => {
    if (!!dataURL && !jobData?.jobId) {
      dispatch(
        saveVideo({ video: dataURL }, (videoUrl) =>{   
          handleClickQuestionsBtn(data, video, videoUrl)}
        )
      );
    }
    handleClickQuestionsBtn(data, video);
  };

  const handleToFinal = () => {
    setIndex(2);
  };
  const handleBack = () => {
    setIndex((index) => index - 1);
  };

  return (
    <div className={styles.main}>
      <ToastContainer />
      <div className={styles.steps}>
        <button
          type="button"
          className={index === index ? styles.active : ``}
          onClick={() => setIndex(0)}
        >
          <First className={styles.icon} />
          Set up
        </button>
        <button
          type="button"
          className={index !== 0 ? styles.active : ``}
          // onClick={handleClickQuestionsBtn}
        >
          <Second className={styles.icon} />
          Questions
        </button>
        <button
          type="button"
          className={index === 2 ? styles.active : ``}
          // onClick={() => setIndex(2)}
        >
          <Third className={styles.icon} />
          Share
        </button>
      </div>
      {index === 0 && <InterviewSetup1 handleNext={handleNext} />}
      {index === 1 && (
        <InterviewSetup2
          handleToFinal={handleToFinal}
          handleBack={handleBack}
        />
      )}
      {index === 2 && <InterviewSetup3 handleBack={handleBack} />}
    </div>
  );
}
