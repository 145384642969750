import { 
    CREATE_JOB_REQUEST, 
    CHECK_FORM_VALIDITY, 
    SET_VIDEO_URL, 
    SET_SAVED_JOB,
} from "../types/job.types";
import { JOBS } from "../actions/actionTypes";

const initialState = {
    jobTitle: '',
    companyName: '',
    videoUrl: '',
    description: '',
    isFormValid: true,
    jobLink: `https://wizr-interview.pages.dev/company-info`,
    jobs: [],
    message: '',
    loading: false
}

export function jobReducer(state = initialState, action){
    switch(action.type){
        case CREATE_JOB_REQUEST:
            return{
                ...state,
                jobTitle: action.payload.jobTitle,
                companyName: action.payload.companyName,
                description: action.payload.description,
            }
        
        case SET_SAVED_JOB:
            return{
                ...state,
                jobTitle: action.payload.title,
                companyName: action.payload.location,
                description: action.payload.description,
                jobId: action.payload._id,
                owner: action.payload.owner,
                loading: false
            }

        case CHECK_FORM_VALIDITY:
            return{
                ...state,
                isFormValid: action.payload,
            }
        
        case SET_VIDEO_URL:
            return{
                ...state,
                videoUrl: action.payload,
            }
        case JOBS.FETCH_ALL_JOBS_REQUEST:
            return{
                ...state,
                loading: true
            }
        case JOBS.FETCH_ALL_JOBS_SUCCESS:
            return{
                ...state,
                loading: false,
                jobs: action.payload.data.jobs,
                message: action.payload.message
            }
        case JOBS.FETCH_ALL_JOBS_ERROR:
            return{
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export default jobReducer;
