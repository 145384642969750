import {
    SAVE_JOB_RESPONSE,
    UPDATE_JOB_RESPONSE,
    GET_JOB_RESPONSE
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  data: null,
  error: null
}

export function jobResponseReducer(state = initialState, action){
    switch(action.type){
        case SAVE_JOB_RESPONSE.INIT:
            return{
                ...state,
                loading: true,
            }
        case SAVE_JOB_RESPONSE.SUCCESS:
            return{
                ...state,
                data: action.payload,
            }

        case SAVE_JOB_RESPONSE.ERROR:
            return{
                ...state,
                error: action.payload,
            }

        case UPDATE_JOB_RESPONSE.INIT:
            return{
                ...state,
                loading: true,
            }
        case UPDATE_JOB_RESPONSE.SUCCESS:

        //     const newArray = [...state.data.submission.submitted_videos];
        //     const index  = newArray.findIndex(
        //             (item) => item?.questionId === action.payload.questionId
        //           );

        //     const result = action.payload.data?.submission.submitted_videos.find(item => item.questionId === action.payload.questionId);
        //     newArray.splice(index, 0, result);
        //    return {
        //     ...state,
        //     data:{submission: {...state.data.submission, submitted_videos: newArray}}
        //     }
        return {
            ...state,
            data: action.payload.data
        }

        case UPDATE_JOB_RESPONSE.ERROR:
            return{
                ...state,
                error: action.payload,
            }
    
        case GET_JOB_RESPONSE.INIT:
            return{
                ...state,
                loading: true,
            }
        case GET_JOB_RESPONSE.SUCCESS:
            return{
                ...state,
                data: action.payload,
            }

        case GET_JOB_RESPONSE.ERROR:
            return{
                ...state,
                error: action.payload,
            }
    

        default:
            return state
    }
}

export default jobResponseReducer;
