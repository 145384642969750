
import styles from "./styles.module.scss";
import { TailSpin } from "react-loader-spinner";
import { useEffect } from "react";

export default function PostFetchLoading({ message }) {

  useEffect(() => {
    document.querySelector("*").classList.add(`${styles.noScroll}`);

    return () => {
      document.querySelector("*").classList.remove(`${styles.noScroll}`);
    };
  }, []);
  return (
    <div className={styles.Loader}>
      <h2>{message}</h2>
      <TailSpin color="#EC7500" height={80} width={80} />
    </div>
  );
}
