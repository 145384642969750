import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import styles from "./styles.module.scss";

export function DashboardNavigation(props){
    const {nextPage, prevPage} = props;

    return(
        <div className={styles.wrapper}>
            {prevPage ? <Link to={prevPage}><ArrowBackIcon /></Link> : ''}
            {nextPage ? <Link to={nextPage}><ArrowForwardIcon /></Link> : ''}
        </div>
    )
}

export default DashboardNavigation;
