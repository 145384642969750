import { createAction } from ".";
import { errorToast, successToast } from "../../components/ui/atoms/Toasts/toasts";
import requestServer from "../../utils/api";
import {
  FETCH_QUESTIONS_FAILURE,
  FETCH_QUESTIONS_REQUEST,
  FETCH_QUESTIONS_SUCCESS,
  DELETE_QUESTION_REQUEST,
  DELETE_QUESTION_SUCCESS,
  DELETE_QUESTION_FAILURE,
  EDIT_QUESTION_REQUEST,
  EDIT_QUESTION_SUCCESS,
  EDIT_QUESTION_FAILURE,
  INCREMENT,
  DECREMENT,
  CREATE_QUESTION_SUCCESS,
  CREATE_QUESTION_REQUEST,
  CREATE_QUESTION_FAILURE,
  DECREMENT_QUESTION,
} from "../types/Questions.types";

export const getInterviewQuestions = (jobId) => {
  return async (dispatch, getState) => {
    dispatch(createAction(FETCH_QUESTIONS_REQUEST));
    try {
      const result = await requestServer("GET", `question/getQuestions/${jobId}`, "");

      if (result.isError) {
        throw new Error(result.messages);
      }

      const { data } = result.data;

      if (data?.questions) {
        dispatch(createAction(FETCH_QUESTIONS_SUCCESS, { data }));
      } else {
        dispatch(
          createAction(FETCH_QUESTIONS_FAILURE, {
            error: "Something wrong happened. Please try again.",
          })
        );
      }
    } catch (error) {
      dispatch(
        createAction(FETCH_QUESTIONS_FAILURE, {
          error: "Something wrong happened. Please try again.",
        })
      );
    }
  };
};

export const deleteQuestion = (questionId) => {
  console.log(questionId, "++");
  return async (dispatch) => {
    dispatch(createAction(DELETE_QUESTION_REQUEST));
    try {
      const response = await requestServer(
        "DELETE",
        "question/deleteQuestion",
        `/${questionId}`
      );

      if (response.isError) {
        throw new Error(response.messages);
      }

      const { data } = response;

      if (data.success) {
        successToast('question deleted succesffuly')
        dispatch(createAction(DELETE_QUESTION_SUCCESS, { data, questionId }));
      } else {
        dispatch(createAction(DELETE_QUESTION_FAILURE, { error: data }));
      }
    } catch (error) {
      dispatch(
        createAction(DELETE_QUESTION_FAILURE, {
          error: "Something wrong happened. Please try again.",
        })
      );
    }
  };
};

export const editQuestion = (changes = null, id) => {
  let updates = changes;
  return async (dispatch, getState) => {
    dispatch(createAction(EDIT_QUESTION_REQUEST));
    try {
  
      const result = await requestServer(
        "PUT",
        "/question/updateQuestion",
        `/${id}`,
        '',
        { 
            text: updates.text,
            owner: updates.owner,
            job_id: id,
            timer: updates.timer,
            retakes: updates.retakes
        }
      );

      if (result.isError) {
        throw new Error(result.messages);
      }

      const { data } = result;
      if (data.success) {
        successToast('question edited successfully')
        return dispatch(createAction(EDIT_QUESTION_SUCCESS, { data }));
      } else {
        errorToast('an error was encountered, please try again')
        return dispatch(
          createAction(EDIT_QUESTION_FAILURE, { error: data.message })
        );
      }
    } catch (error) {
      return dispatch(
        createAction(EDIT_QUESTION_FAILURE, {
          error: "Something wrong happened. Please try again.",
        })
      );
    }
  };
};

export const addQuestion = () => {
  return async (dispatch) => {
    dispatch(createAction(INCREMENT));
  };
};

export const removeQuestion = (id) => {
  return async (dispatch) => {
    dispatch(createAction(DECREMENT_QUESTION, { id }));
  };
};

export const newQuestion = (body) => {
  let payload = body
  return async (dispatch) => {
    dispatch(createAction(CREATE_QUESTION_REQUEST));
    try {
      const result = await requestServer(
        "POST",
        "/question", "/createQuestion",
        '',
       payload
      );
      if (result.isError) {
        throw new Error(result.messages);
      }

      const { data } = result;
      
      if (data.success) {
        // let id = body?._id
        //   dispatch(createAction(DECREMENT_QUESTION, {id} ))
  
        return dispatch(createAction(CREATE_QUESTION_SUCCESS, { data }));
      } else {
        return dispatch(
          createAction(CREATE_QUESTION_FAILURE, { error: data.message })
        );
      }
    } catch (error) {
      dispatch(
        createAction(CREATE_QUESTION_FAILURE, {
          error: "Something wrong happened. Please try again.",
        })
      );
    }
  };
};
