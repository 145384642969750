import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Items from "../InterviewItems";
import styles from "./style.module.scss";
import profile from "../../../../assets/images/profile-picture.webp";
import { ReactComponent as GridLogo } from "../../../../assets/images/grid-i.svg";
import { ReactComponent as RowLogo } from "../../../../assets/images/row-i.svg";
import SearchBar from "../../molecules/SearchBar";
import { interviewsData } from "../../../../constants/interviewsData";

function Container({ title, image }) {
    const [type, setType] = useState("row");

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.heading}>
                    <div className={styles.titleSearch}>
                        <div className={styles.title}>
                            {image && <img src={profile} alt="profile" />}
                            <h1>{title}</h1>
                        </div>
                        <SearchBar extraClass="applicants-search" />
                    </div>
                    <div className={styles.buttons}>
                        <button className={`${type === "grid" && styles.active}`} onClick={() => setType("grid")}><GridLogo /></button>
                        <button className={`${type === "row" && styles.active}`} onClick={() => setType("row")}><RowLogo /></button>
                    </div>
                </div>
                <Items type={type} list={interviewsData} />
            </div>
        </div>
    );
}

export default Container;
Container.propTypes = {
    title: PropTypes.string,
    image: PropTypes.bool,
    list: PropTypes.array,
};
