import React from "react";
import styles from "./style.module.scss";
import PropTypes from "prop-types";

function PopupButton({ title, openPopup, extraClass }) {
  return (
    <>
      <button
        className={`${styles.PopupButton} ${styles[extraClass]}`}
        onClick={openPopup}
      >
        {title}
      </button>
    </>
  );
}
export default PopupButton;
PopupButton.propTypes = {
  title: PropTypes.string,
  openPopup: PropTypes.func,
  extraClass: PropTypes.string,
};
