import React, {useState} from "react";

import PropTypes from "prop-types";

import styles from "./styles.module.scss";
import LibraryCard from "../../atoms/LibraryCard";

const GridView = ({list, pathHandler}) => {

    return (
        <div className={styles.gridContainer}>
            {
                list.map((d, i) => {
                    return <LibraryCard item={d}
                        listIndex={i} pathHandler={pathHandler}
                        {...d} key={i}
                    />;
                })
            }
        </div>
    );
};

export default GridView;
GridView.propTypes = {
    list: PropTypes.array,
    pathHandler: PropTypes.func
};
