import React from "react";
import styles from "./style.module.scss";
import PropTypes from "prop-types";

export default function DropdownMenu({children, onMouseLeave }) {
  return (
    <div className={`${styles.dropdown}`} onMouseLeave={onMouseLeave}>
        {children}
    </div>
  );
}

DropdownMenu.propTypes = {
  handleClick: PropTypes.func,
  handleKeyDown: PropTypes.func,
  onMouseLeave: PropTypes.func,
  options: PropTypes.array
};
