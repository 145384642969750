import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { VideoPlayer } from "@master-wizr/video-player";
import styles from "./style.module.scss";
import SharedButton from "../../atoms/SharedButton";
import rectangle from "../../../../assets/images/rectangle.png";
import RecordTabs from "../VideoRecords/RecordTabs";
import placeholder from "../../../../assets/images/create.png";

import {
  saveJobResponse,
  updateJobResponse,
} from "../../../../redux/actions/jobResponseActions";
import { CoPresentOutlined } from "@mui/icons-material";
import VideoRecorderComponent from "../VideoRecorderComponent";

function MyVideoDashboard({
  name,
  videoItem,
  items,
  submissionId,
  submissionData,
  setActiveVideo,
  tempVideo,
  setTempVideo,
  currentVideoCount,
  setCurrentVideoCount,
  finishOnboarding,
  setSubmissionLoading
}) {
  const [recordingPopup, openRecordingPopup] = useState(null);
  // const [newUploadChanges, setNewUploadChanges] = useState(null);


  const submittedVideo = (id) => {
    console.log(submissionData?.submission?.submitted_videos)
    return (
      submissionData?.submission?.submitted_videos?.find(
        (video) => video.questionId === id
      ) || null
    );
  };

  useEffect(() => {
    let VideosObject = {};
    items.questions?.map((item) => {
      VideosObject[item._id] = {
        questionId: item._id,
        video_url: submittedVideo(item._id)?.video_url,
      };
      setTempVideo(VideosObject);
    });
  }, [submissionData]);


  useEffect(() => {
    if(document.querySelector("button.mainplaybtn")) {
      const allPlayersBtns = document.querySelectorAll("button.mainplaybtn");
      for(let i = 0; i < allPlayersBtns.length; i++) {
        !allPlayersBtns[i].classList.value.includes(`${styles.playBtn}`) && allPlayersBtns[i].classList.add(`${styles.playBtn}`);
      }
  }
}, [tempVideo, videoItem]);

const objToArr = (obj) => {
  const arr = [];
  for (let key in obj) {
    obj[key].video_url && arr.push(obj[key]);
  }
  return arr;
}

  const dispatch = useDispatch();

  const callback = () => {
    setSubmissionLoading(null);
    finishOnboarding();
  }

  const handleContinue = () => {
    // stopRecording()
    // convert tempArray to array of Objects
    const converted = objToArr(tempVideo);
    if (converted.length === items.questions.length) {
      setSubmissionLoading("Uploading your new submissions...");
      const body = {
        submitted_videos: converted,
      };
      dispatch(updateJobResponse(submissionId, body, callback));
    } else {
      // can include a warning here
      for(let i = 0; i < items?.questions.length; i++) {
        if(!tempVideo[items?.questions[i]._id]?.video_url) {
          setActiveVideo(items?.questions[i]);
          break;
        }
      }
    }
  };

  let itemIndex =
    items?.questions?.indexOf(videoItem) === -1
      ? 0
      : items?.questions?.indexOf(videoItem);

  return (
    <>
      {recordingPopup && (
        <VideoRecorderComponent
          item={recordingPopup}
          setTempVideo={setTempVideo}
          exit={() => openRecordingPopup(null)}
        />
      )}
      <div className={styles.myVideo}>
        <h1>
          {submissionData?.submission?.user?.fullName ||
            submissionData?.submission?.user?.full_name ||
            "MW"}{" "}
          - <span>My Video Dashboard</span>
        </h1>
        <div className={styles.paragraphLink}>
          <h5>WIZR Connect</h5>
          <span>-</span>
          <Link to="/re-edit">Re-edit videos here</Link>
        </div>
        <div className={styles.videoQuestion}>
          <div className={styles.videoWrapper}>
            {tempVideo && tempVideo[videoItem?._id]?.video_url ? (
              <VideoPlayer
                preload="auto"
                type="fullscreen"
                src={tempVideo[videoItem?._id]?.video_url}
              />
            ) : (
              <img aria-hidden onClick={() => openRecordingPopup(videoItem)} src={placeholder} alt="empty-vid" />
            )}
          </div>
          <div>
            {videoItem && <h1>Question {itemIndex + 1}</h1>}
            {videoItem && <p>{videoItem?.text}</p>}
            <div className={styles.buttons}>
              <SharedButton
                children={
                  tempVideo[videoItem?._id]?.video_url
                    ? "Re-Take Video"
                    : "Record video"
                }
                extraClass="lightGrayBtn"
                clickEvent={() => openRecordingPopup(videoItem)}
              />
              <SharedButton
                children="Accept & Continue"
                clickEvent={handleContinue}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default MyVideoDashboard;
MyVideoDashboard.propTypes = {
  name: PropTypes.string,
  firstdescription: PropTypes.string,
  seconddescription: PropTypes.string,
  videoItem: PropTypes.object,
};
