import {useEffect, useRef} from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";
import MenuNav from "./nav";
import MenuItem from "../../molecules/MenuItem";
import { ReactComponent as RecordIcon } from "../../../../assets/menu/record.svg";
import { ReactComponent as BookEventIcon } from "../../../../assets/menu/book.svg";
import { ReactComponent as ScheduleMeetingIcon } from "../../../../assets/menu/schedule.svg";
import { ReactComponent as SpacesIcon } from "../../../../assets/menu/3d-spaces.svg";
import { ReactComponent as UsersIcon } from "../../../../assets/menu/users.svg";
import { ReactComponent as UserIcon } from "../../../../assets/menu/user.svg";
import { ReactComponent as FavoriteIcon } from "../../../../assets/menu/favorite.svg";
import { ReactComponent as ArchivedIcon } from "../../../../assets/menu/archived.svg";
import { ReactComponent as FolderIcon } from "../../../../assets/menu/folder.svg";
import { ReactComponent as SharedIcon } from "../../../../assets/menu/shared.svg";
import { ReactComponent as CreateIcon } from "../../../../assets/menu/create.svg";
import { ReactComponent as ReviewIcon } from "../../../../assets/menu/review.svg";
import { ReactComponent as SettingsIcon } from "../../../../assets/menu/settings.svg";
import { ReactComponent as InfoIcon } from "../../../../assets/menu/info.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/menu/close.svg";

function UniversalMenu({handleShowMenu}) {
  const menuRef = useRef()
  const topAdjusterOnScroll = () => {
    let scrollValue  = window.scrollY
    const WrapperTop = (scrollValue <= 45) ? `${45 - scrollValue}px` : '0px'
    menuRef.current.style.top = WrapperTop
  }

    useEffect(() => {
      window.addEventListener("scroll", topAdjusterOnScroll)
      topAdjusterOnScroll()
      return () => {
        window.removeEventListener("scroll", topAdjusterOnScroll)
      }
    }, [])
    
    
    
    return (
        <div className={styles.wrapper} ref={menuRef}>
            <CloseIcon className={styles.close} onClick={handleShowMenu} />
            <MenuNav />
            <h3>Actions</h3>
            <MenuItem label="Start recording" icon={<RecordIcon />} />
            <MenuItem label="Book event" icon={<BookEventIcon />} />
            <MenuItem label="Schedule meeting" icon={<ScheduleMeetingIcon />} />
            <MenuItem label="Use 3D spaces" icon={<SpacesIcon />} />
            <h3>Saving options</h3>
            <MenuItem label="Personal" icon={<UserIcon />} />
            <MenuItem label="Favorite" icon={<FavoriteIcon />} />
            <MenuItem label="Archived" icon={<ArchivedIcon />} />
            <h3>Overviews</h3>
            <MenuItem label="Meeting repository" icon={<FolderIcon />}  />
            <MenuItem label="Team" icon={<UsersIcon />}  />
            <MenuItem label="Shared with me" icon={<SharedIcon />}  />
            <h3>Video processes</h3>
            <MenuItem label="Create new" icon={<CreateIcon />}  />
            <MenuItem label="Review" icon={<ReviewIcon />}  />
            <h3>Settings & info</h3>
            <MenuItem label="Settings" icon={<SettingsIcon />}  />
            <MenuItem label="About WiZRConnect" icon={<InfoIcon />}  />
        </div>
    );
}

export default UniversalMenu;
UniversalMenu.propTypes = {
    handleShowMenu: PropTypes.func,
  };