import React, { useState } from "react";
import TwoButtons from "../atoms/TwoButtons";
import Popup from "../atoms/Popup";
import PopupButton from "../atoms/Popup/PopupButton";
import PropTypes from "prop-types";

function RenamePopup({
  title,
  color,
  text,
  firsttext = "Yes",
  secondtext = "No",
  handleFunction,
}) {
  const [popup, setPopup] = useState(false);

  const hidepopup = () => {
    setPopup(false);
  };
  const openpopup = () => {
    setPopup(true);
  };
  return (
    <>
      <PopupButton title={title} extraClass={color} openPopup={openpopup} />
      <Popup removePopup={hidepopup} title={title} state={popup}>
        {text ? (
          <p>{text}</p>
        ) : (
          <>
            <h4>Title</h4>{" "}
            <input
              type="text"
              placeholder="Enter the new title of the video "
            />
          </>
        )}

        <TwoButtons
          handleFunction={handleFunction}
          firsttext={firsttext}
          secondtext={secondtext}
          extraClass="grayBtn"
        />
      </Popup>
    </>
  );
}

RenamePopup.propTypes = {
  handleFunction: PropTypes.func,
  title: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.string,
  firsttext: PropTypes.string,
  secondtext: PropTypes.string,
};

export default RenamePopup;
