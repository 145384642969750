import {
  FETCH_QUESTIONS_FAILURE,
  FETCH_QUESTIONS_REQUEST,
  FETCH_QUESTIONS_SUCCESS,
  DELETE_QUESTION_SUCCESS,
  EDIT_QUESTION_SUCCESS,
  CREATE_QUESTION_SUCCESS,
  CREATE_QUESTION_REQUEST,
  INCREMENT,
  DECREMENT_QUESTION,
  DELETE_QUESTION_REQUEST,
  DELETE_QUESTION_FAILURE
} from "../types/Questions.types";

const INITIAL_STATE = {
  success: false,
  loading: false,
  fetchingQuestions: false,
  data: [],
  totalCount: 0,
  editedQuestion: {},
  edited: false,
  error: "",
  uploading: false,
};

const newQuestion = {
  text: "New Question?",
  owner: "62a7946da561f19d7f0c8df6",
  timer: 1,
  retakes: 1
};

const questionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_QUESTIONS_REQUEST:
      return { ...state, success: false, error: "", fetchingQuestions: true };

    case FETCH_QUESTIONS_SUCCESS:
      return {
        ...state,
        success: true,
        fetchingQuestions: false,
        error: "",
        data: [...action.data.questions],
        totalCount: action.data.length,
      };
    case FETCH_QUESTIONS_FAILURE:
    return {
      ...state,
      success: false,
      fetchingQuestions: false,
      error: true,
    };
    case DELETE_QUESTION_REQUEST:
      return { ...state, success: false, error: "" };
    case DELETE_QUESTION_SUCCESS:
      return {
        ...state,
        data: state.data.filter((q) => q._id !== action.questionId),
        edited: true,
        totalCount: state.data.totalCount - 1,
      };
    case DELETE_QUESTION_FAILURE:
      return {
        ...state,
        success: false,
        error: true,
      };
    case EDIT_QUESTION_SUCCESS:
      const updatedIdx = state.data.findIndex(
        (item) => item._id === action.data.data.question._id
      );
     
      let update = [...state.data]
  
      update[updatedIdx] = action.data.data.question;
    
      return {
        ...state,
        loading: false,
        data: [...state.data[updatedIdx], update[updatedIdx]],
        edited: true,
      };
    case CREATE_QUESTION_REQUEST:
      return {
        ...state,
        success: false,
        loading: true,
        edited: false,
      };
    case CREATE_QUESTION_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        data: [...state.data, action.data.data.question],
        edited: false,
      };
    case INCREMENT:
      return {
        ...state,
        success: false,
        data: [...state.data, {...newQuestion, text: "New Question ?"}],
      };
  
    case DECREMENT_QUESTION:
      return {
        ...state,
        data: state.data.filter((q) => (q._id !== undefined)),
      };

    default:
      return state;
  }
};

export default questionsReducer;
