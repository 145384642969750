import React, { useEffect } from "react";
import moment from "moment";
import styles from "./style.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as MyLink } from "../../../../assets/images/link.svg";
import { getShares } from '../../../../redux/actions/sharesActions';
import Loader from "../../atoms/Loader";
import NothingToDisplay from "../../atoms/NothingToDisplay";

function JobPositionsContainer({list}) {
  const rowTitles = ['positions', 'date added', 'applicants', 'organizer'];
  const jobShareLink = useSelector(state => state.shares.shares.shareLink);
  const loading = useSelector(state => state.job.loading);

  const dispatch = useDispatch();
  const navigateToJob = async (e, job) => {
    e.preventDefault();
    await dispatch(getShares(job._id))
  }
  
  useEffect(() => {
    if(jobShareLink) {
      return window.location.href = jobShareLink 
    }
  }, [jobShareLink])
  return (
    loading ? <Loader /> : 
      <div className={styles.container}>
      <div className={styles.content}>
        <table className={styles.tableContainer}>
          <tbody>
            <tr className={styles.title}>
              {rowTitles.map((item, index) => (<th key={index}>{item}</th>))}
            </tr>
            {
                list && list.length === 0 ? <div className={styles.nothingToDisplay}><NothingToDisplay /></div> : 
                list && list.map((item, index) => (
                  <tr className={styles.row} key={index}>
                      <td data-label="position" onClick={e => navigateToJob(e, item)} className={styles.position}>
                        {item.title}
                      </td>
                      <td data-label="date added">
                        {moment(item.createdAt).format("D MMM, yy")}
                        &nbsp;at&nbsp;
                        {moment(item.createdAt).format("HH:mm")}
                      </td>
                      {/* <td data-label="date ended">{item.dateEnded}</td> */}
                      <td data-label="applicants">{item.applicants}</td>
                      <td data-label="organiser" className={styles.lastItem}>
                        <div>{item.organiser || '--'}</div>
                        <div className={styles.dots}>
                          <MyLink />
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </td>
                  </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default JobPositionsContainer;
