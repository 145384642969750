import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";


export function authenticateUser() {
    const token = Cookies.get("authCode");
    if (!token) return [false];
    const decoded = jwtDecode(token);
    if (!decoded || !decoded.exp) return [false];
    const now = new Date();
    const nowInSec = Math.floor(now.getTime() * 0.001);
    console.log(decoded.exp > nowInSec, decoded.exp, nowInSec, decoded);
    return [decoded.exp > nowInSec, decoded];
   
  }