import React from "react";
import styles from "./style.module.scss";

export function NothingToDisplay(){
    return(
        <div className={styles.wrapper}>
            Nothing to display :(
        </div>
    )
}

export default NothingToDisplay; 
