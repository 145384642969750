import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import TopNavigation from "../../ui/molecules/TopNavigation";

function InterviewPanel({ children }) {
  const sharedJobData = useSelector(state => state.shares.sharedJob?.job);
  return (
    <>
        <TopNavigation  interview  title={sharedJobData ? sharedJobData.title : "Sales Manager"}/>
        { children }
    </>
  );
}
export default InterviewPanel;
InterviewPanel.propTypes = {
  children: PropTypes.node
};