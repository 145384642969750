import GetStartedDash from "../molecules/GetStarted";
import {objectives,responsibilities,skills} from "../../../constants/GetStarted";

function GetStartedWithoutVideo() {
  return (
    <>
      <GetStartedDash title="Sales Manager" objectives={objectives} responsibilities={responsibilities} skills={skills}/>
    </>
  );
}

export default GetStartedWithoutVideo;
