import requestServer from "../../utils/api/index";
import {
  CREATE_JOB_REQUEST,
  CHECK_FORM_VALIDITY,
  SET_VIDEO_URL,
  POST_JOB,
  SET_SAVED_JOB,
} from "../types/job.types";
import {
  getJobsRequest,
  getJobsSuccess,
  getJobsError,
} from "./actionCreators/jobActionCreators";

export const createJobReducer = () => {
  return CREATE_JOB_REQUEST;
};

export const postJob = () => {
  return POST_JOB;
};

export const checkFormValidity = () => {
  return CHECK_FORM_VALIDITY;
};

export const setVideoURL = (payload) => ({
  type: SET_VIDEO_URL,
  payload,
});

export const setSavedJob = (payload) => ({
  type: SET_SAVED_JOB,
  payload,
});

export const postJobtoDB = (body, video, videoUrl, setIndex) => async (dispatch) => {
  try {
    const response = await requestServer("POST", "/job", `/createJob`, "", {
      title: body.jobTitle,
      description: body.description,
      intro_video_url: videoUrl || video,
      location: body.companyName,
    });
    dispatch(setSavedJob(response.data.data.job));
    setIndex && setIndex(1);
  } catch (error) {
    console.log(error);
  }
};

export const getAllJobs = () => async (dispatch) => {
  dispatch(getJobsRequest());
  const response = await requestServer("GET", "/job", "/getAllJobs", "");
  try {
    if (response.status === 200) {
      dispatch(getJobsSuccess(response.data));
    } else {
      dispatch(getJobsError(response.data.message));
    }
  } catch (error) {
    dispatch(getJobsError(error));
  }
};
