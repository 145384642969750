import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { UniversalMenu } from "@master-wizr/universal-menu";
import UniversalMenu from "../../organisms/UniversalMenu"

const initialValues = {
  CURRENT_ORG: "engineering",
  mode: "dark",
  displayValue: "-100",
  accounts: [
    "Intranet",
    "Sales",
    "HR",
    "Training",
    "Community",
    "Manuals",
    "Management",
    "Marketing",
    "Investors",
  ],
};
export default function UniversalMenuComponent({ showMenu, handleShowMenu }) {
  const [values, setProps] = useState(initialValues);
  const navigate = useNavigate();

  useEffect(() => {
    setProps(old => ({
      ...old,
      displayValue: showMenu ? "-100" : "0",
    }));
  }, [showMenu]);
  const toggleLight = () => {
    if (values.mode === "light") {
      return console.log(
        `mode is already "${values.mode}". Not further changes happended`
      );
    }
    setProps(
      prevProps => ({
        ...prevProps,
        mode: "light",
      }),
      console.log(`mode is now "light"`)
    );
  };
  const toggleDark = () => {
    if (values.mode === "dark") {
      return console.log(
        `mode is already "${values.mode}". Not further changes happended`
      );
    }
    setProps(
      prevProps => ({
        ...prevProps,
        mode: "dark",
      }),
      console.log(`mode is now "dark"`)
    );
  };
  const handleCreateShowroom = () => console.log("callcreate show room fn");
  const handleCreateCoverPages = () => console.log("call create cover page fn");
  const toggleMenu = () => {
    handleShowMenu();
  };
  const logout = () => {
    alert("This is in dev mode, You will have to add a logout functionality");
  };
  const switchAccount = portal => {
    const name = portal?.toLowerCase();
    const path = window.location.pathname;
    const get_current_org = path.split("/");
    setProps(prevProps => ({ ...prevProps, CURRENT_ORG: name }));
    let newPath = name;
    if (get_current_org.length > 2) {
      newPath = path.replace(get_current_org[1], name);
    }
    return navigate(newPath);
  };

  useEffect(() => {
    if (values.mode === "light") {
      document.body.classList.add("bright-mode");
    } else if (values.mode === "dark") {
      document.body.classList.remove("bright-mode");
    }
  }, [values.mode]);
  useEffect(() => {
    if (values.displayValue === "-100") {
      document.body.classList.add("universalmenu-closed");
    } else if (values.displayValue === "0") {
      document.body.classList.remove("universalmenu-closed");
    }
  }, [values.displayValue]);
  return (
    !showMenu && <UniversalMenu handleShowMenu={handleShowMenu} />
  );
}
UniversalMenuComponent.propTypes = {
  showMenu: PropTypes.bool,
  handleShowMenu: PropTypes.func,
};
