import React, { useState } from "react";
import styles from "./style.module.scss";
import { Link } from "react-router-dom";
import { VideoPlayer } from "@master-wizr/video-player";
import Button from "../../atoms/SharedButton";
import VideoRecorderComponent from "../VideoRecorderComponent";
import { useDispatch } from "react-redux";
import { setVideoURL } from "../../../../redux/actions/job.actions";


export default function VideoContent({
  extraClass, // for the list
  title, //title under the video
  desc, // description under the video
  listTitle, // title in the right side (list side)
  listDesc, // description for list
  list,
  unorderedList, // unordered list with two buttons
  defaultList, // list with one button
  subtitle, // under the title of list
  extraClass1, // for the whole wrapper
  videoUrl,
  shareId,
  handleFileChange,
}) {
  const [recording, setRecording] = useState(false);
  const dispatch = useDispatch();

  function handelPopup() {
    setRecording(true);
  }

  const closeRecording = () => {
    setRecording(false);
  };

  const handleVideoUrl = (url) => {
    dispatch(setVideoURL(url));
  };


  return (
    <>
      <div className={`${styles.wrapper} ${styles[extraClass1]}`}>
        <div className={styles.videoHolder}>
          <div className={styles.videoContainer}>
            <VideoPlayer type="fullscreen" src={videoUrl ? videoUrl : ''} />
          </div>
          <h2>{title}</h2>
          <p>{desc}</p>
        </div>
        <div className={`${styles.listHolder} ${styles[extraClass]}`}>
          <h2>{listTitle}</h2>
          <h3>{subtitle}</h3>
          <p>{listDesc}</p>
          <ul>
            {list.map((item, i) => {
              return <li key={i}>{item}</li>;
            })}
          </ul>
          {defaultList && (
            <Link to={`/get-started/${shareId}`}>
              <Button extraClass="button">Get Started</Button>
            </Link>
          )}
          {unorderedList && (
            <div className={styles.buttons}>
              <Button extraClass="button" clickEvent={handelPopup}>
                Record Video
              </Button>
              <span>Or</span>
              {/* <Link to="/set-your-information"><Button extraClass="grayBtn1">Upload Video</Button></Link> */}
              {!recording && <Button extraClass="grayBtn1">
                <label for="upload-video" class="custom-file-upload">
                  {" "}
                  Upload video{" "}
                </label>
                <input
                  id="upload-video"
                  style={{ display: "none" }}
                  type="file"
                  onChange={handleFileChange}
                  accept=".mov,.mp4"
                />
              </Button>}
            </div>
          )}
        </div>
      </div>
      {recording && (
        <VideoRecorderComponent
          exit={closeRecording}
          setTempVideo={handleVideoUrl}
          />
      )}
    </>
  );
}
