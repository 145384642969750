import { JOBS } from "../actionTypes";

export const getJobsRequest = () => ({
    type: JOBS.FETCH_ALL_JOBS_REQUEST
})

export const getJobsSuccess = payload => ({
    type: JOBS.FETCH_ALL_JOBS_SUCCESS,
    payload
})

export const getJobsError = error => ({
    type: JOBS.FETCH_ALL_JOBS_ERROR,
    error
})
