import React from "react";
import MainPopup from "../molecules/mainPopup";

export default function donePopup() {
  return (
    <div>
      <MainPopup />
    </div>
  );
}
