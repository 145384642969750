import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import VideoContent from "../molecules/videoContent/index.js";
import SecondBanner from "../atoms/secondBanner";
import { getShareJobData } from "../../../redux/actions/sharesActions";
import { useParams, useNavigate } from 'react-router-dom';

export default function CompanyInfo() {
  const listitems = [
    "Welcome Greeting",
    "About 50+",
    "Videos made simple",
    "Why Show Rooms",
  ];
  const dispatch = useDispatch();
  const shareId  = useParams().id;
  const navigate = useNavigate();

  const sharedJobData = useSelector(state => state.shares.sharedJob.job);
  useEffect(() => {
    if(shareId !== ('' || null || undefined)){
      dispatch(getShareJobData(shareId)).then(() => {
        navigate(`/company-info/${shareId}`);
      })
    }
  }, [shareId])

  const videoUrl = useSelector(state => state.shares.sharedJob.job?.intro_video_url);
  const title = useSelector(state => state.shares.sharedJob.job?.title);

  return (
    <div>
      <SecondBanner />
      <VideoContent
          videoUrl={videoUrl}
          defaultList
          list={listitems}
          title={title || "Welcome to 50+ seeking Sales Manager"}
          desc={sharedJobData?.description}
          listTitle="Video Tutorials"
          listDesc="Explore the provided information. Before you start your application process you may will receive additional information."
          subtitle="Opportunity to explain the next steps"
          shareId={shareId}
      />
    </div>
  );
}
