import { useState } from "react";
import Popup from "../atoms/Popup";
import TwoButtons from "../atoms/TwoButtons";
import PopupButton from "../atoms/Popup/PopupButton";
import PropTypes from "prop-types";

function DeletePopup({ handleFunction, firsttext, secondtext, text }) {
  const [popup, setPopup] = useState(false);

  const hidepopup = () => {
    setPopup(false);
  };
  const openpopup = () => {
    setPopup(true);
  };
  console.log(popup);
  return (
    <>
      <PopupButton title="Delete" extraClass="grayBtn1" openPopup={openpopup} />
      <Popup removePopup={hidepopup} state={popup} title="Delete">
        <p>{text}</p>
        <TwoButtons
          firsttext={firsttext}
          secondtext={secondtext}
          extraClass="grayBtn"
          handleFunction={handleFunction}
        />
      </Popup>
    </>
  );
}
DeletePopup.propTypes = {
  handleFunction: PropTypes.func,
  firsttext: PropTypes.string,
  secondtext: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.string,
};

export default DeletePopup;
