import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux"
import styles from "./style.module.scss";
import PropTypes from "prop-types";
import QuestionsCard from "../../atoms/QuestionCards";
import TwoButtons from "../../atoms/TwoButtons";
import {
    getInterviewQuestions,deleteQuestion, editQuestion, addQuestion, newQuestion, removeQuestion
  } from "../../../../redux/actions/question.actions"
import { warningToast } from "../../atoms/Toasts/toasts";
import Loader from "../../atoms/Loader";
  

function InterviewSetup2(props) {

    const [addCard, showAddCard] = useState(true);
    
    const addNewQuestionCard = () => {
        showAddCard(addCard => !addCard);
        if(addCard) {
            props?.addQuestion();
        }
    }

    const {data} = props?.questions
    const nextStep = () => {
        if(data?.length >= 3){
            props?.handleToFinal()
        }
        else
        warningToast('a minimum of three(3) questions is required')
    }

    const backStep = () => {
        props?.handleBack()
    }

    const loading = useSelector(state => state.questions.loading);
    
    const {job} = props
    return (
            loading ? <Loader /> : 
        <div className={styles.interviewSetup2} >
            <h1>Make Questions</h1>
            <div className={styles.questionContainer}>
                {data.map((question, i) => {
                    return (
                        <QuestionsCard 
                            key={i}
                            index={i}
                            question = {question}
                            success = {question?.success}
                            job = {job}
                            onDelete = {() => props?.deleteQuestion(question?._id)}
                            onEdit = {() => props?.editQuestion(question, question._id)}
                            addNewQuestionCard = {()=>addNewQuestionCard()} 
                            newQuestion = {() => props?.newQuestion(question)}
                            removeQuestion = {() => props?.removeQuestion(question._id)}
            
                        />
                    );
                })}
                {addCard && <QuestionsCard addNewQuestionCard = {()=>addNewQuestionCard()}  description="Add your question" addCard/>}
            </div>
            <div className={styles.buttons}>
                <TwoButtons 
                    firsttext="Back" 
                    secondtext="Next" 
                    extraClass="lightGrayBtn" 
                    handleSecondButtonClick={()=>nextStep()}
                    handleFirstButtonClick={()=>backStep()}
                />
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
      questions: state.questions,
      edited: state.questions.edited,
      job: state.job
    }
  }
  
const mapDispatchToProps = dispatch => {
    return {
        getInterviewQuestions: async(jobId) => dispatch(getInterviewQuestions(jobId)),
        deleteQuestion:  async(questionId) => await dispatch(deleteQuestion(questionId)),
        editQuestion: async(payload, id) => await dispatch(editQuestion(payload, id)),
        addQuestion: async()=> await dispatch(addQuestion()),
        newQuestion: async(payload) => await dispatch(newQuestion(payload)),
        removeQuestion: async(payload) => await dispatch(removeQuestion(payload)),

        
    }
  }
  
export default  connect(mapStateToProps, mapDispatchToProps) (InterviewSetup2);
InterviewSetup2.propTypes = {
    searchData: PropTypes.array,
    setOpenDashboard: PropTypes.func,
    invite: PropTypes.bool,
};
