import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SharedBanner from "../atoms/SharedBanner";
import SearchComponent from "../molecules/InterviewsContainer/searchComponent";
import GridView from "../molecules/GridView";
import JobPositionsContainer from "../molecules/JobPositionsContainer";
import { getAllJobs } from "../../../redux/actions/job.actions";

function JobPositions() {
  const [type, setType] = useState("row");

  const handleGridView = () => setType("grid");
  const handleRowsView = () => setType("row");

  const dispatch = useDispatch();
  useEffect(() => dispatch(getAllJobs()), []);
  const jobPositions = useSelector(state => state.job.jobs.results);

  return (
    <>
      <SharedBanner 
        title="Currently open roles"
        description="Here are some key stats and links to help you get the most out of connections today."
        extraClass1="interview"
        extraClass="interviewBanner"
        extraBtn
      />
      <SearchComponent 
        title="All job posts"
        handleGridView={handleGridView}
        handleRowsView={handleRowsView}
         />
      {type === "row" ? (
        <JobPositionsContainer list={jobPositions} />
      ) : (
        <GridView list={jobPositions} />
      )}
    </>
  );
}

export default JobPositions;
