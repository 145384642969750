import {
    getCommentsRequest,
    getCommentsSuccess,
    getCommentsError,
    addCommentRequest,
    addCommentSuccess,
    addCommentError,
    deleteCommentRequest,
    deleteCommentSuccess,
    deleteCommentError,
    editCommentRequest,
    editCommentSuccess,
    editCommentError
} from "./actionCreators/commentsActionCreators";
import requestServer from "../../utils/api";

export const getComments = () => async dispatch => {
    dispatch(getCommentsRequest());
    try {
        const response = await requestServer(
            'GET',
            '/comment',
            '/getAllComments'
        );
        if (response.status === 200){
            dispatch(getCommentsSuccess(response.data));
        }
        else {
            dispatch(getCommentsError(response.data.message));
        }

    } catch (error) {
        dispatch(getCommentsError(error));
    }
};

export const addComment = (comment) => async dispatch => {
    dispatch(addCommentRequest());
    try {
        const response = await requestServer(
            'POST',
            '/comment',
            '/createComment',
            '',
            {
                text: comment, 
                submission_id: '62b447271cade61f0bcfb8d6',
                author: '62bbf766b3dfc6e7503b636c'
            }
        );
        if (response.status === 201){
            dispatch(addCommentSuccess(response.data));
        }
        else {
            dispatch(addCommentError(response.data.message));
        }
    } catch (error) {
      dispatch(addCommentError(error));
    }
};


export const deleteComment = (id) => async dispatch => {
    dispatch(deleteCommentRequest());
    try{
        const response = await requestServer(
            'DELETE',
            '/comment/deleteComment',
            `/${id}`
        )

        if (response.status === 200){
            dispatch(deleteCommentSuccess(response.data));
        }
        else {
            dispatch(deleteCommentError(response.data.message));
        }
    } catch(error){
        dispatch(deleteCommentError(error));
    }
}

export const editComment = (id, text) => async dispatch => {
    dispatch(editCommentRequest());
    try{
        const response = await requestServer(
            'PUT',
            '/comment/updateComment',
            `/${id}`,
            '',
            {
                text: text, 
                submission_id: '62b447271cade61f0bcfb8d6',
                author: '62bbf766b3dfc6e7503b636c'
            }
        )

        if (response.status === 200){
            dispatch(editCommentSuccess(response.data));
        }
        else {
            dispatch(editCommentError(response.data.message));
        }
    } catch(error){
        dispatch(editCommentError(error));
    }
}
