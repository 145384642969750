import axios from 'axios';
import env from '../../settings/configs/env';

const axiosInstance = axios.create({
    baseURL: `${env.API_URL}/api/v1` ,
    headers: {'Content-Type': 'application/json'},
    // withCredentials: true
  });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => error.response ? error.response : Promise.reject(error)
  );

const requestServer = (method, service, endpoint, queryParams = undefined, data = undefined) => {
    const options = {
        method,
        url: `${service}${endpoint}`,
        headers: { 'Content-Type': 'application/json', responseType: 'json', 'Authorization': `Bearer ${localStorage.getItem('authToken')}`}
    }
    if (queryParams) options.params = queryParams;
    if (data) options.data = data;
    return axiosInstance(options)
}

export default requestServer;