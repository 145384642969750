import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import PropTypes from "prop-types";
import { VideoPlayer } from "@master-wizr/video-player";
import styles from "./style.module.scss";
import { Link } from "react-router-dom";
import SharedButton from "../../atoms/SharedButton";
import TitleParagraphs from "../../atoms/TitleParagraphs";
import video from "../../../../assets/video/video.mp4";
import { getShareJobData } from "../../../../redux/actions/sharesActions";

function GetStarted({ objectives, responsibilities, skills, hasVideo }) {
    const dispatch = useDispatch();
    const shareId  = useParams().id;
    const videoUrl = useSelector(state => state.shares.sharedJob.job?.intro_video_url);
    const title = useSelector(state => state.shares.sharedJob.job?.title);
    const desc = useSelector(state => state.shares.sharedJob.job?.description);
    const location = useSelector(state => state.shares.sharedJob.job?.location);
  
    useEffect(() => {
      if(shareId !== ''){
        dispatch(getShareJobData(shareId))
      }
    }, [shareId])
  
    return (
        <div className={styles.getStarted}>
            <h1>{location} Interview - <span>{title}</span></h1>
            <p className={styles.location}>Norway, Oslo</p>
            {hasVideo &&
                <VideoPlayer type="fullscreen" src={videoUrl || video} />
            }
            <p className={styles.job}>Job Description</p>
            {/* <Link to="https://masterwizr.com/">https://masterwizr.com/</Link> */}
            <p className={styles.paragraph}>
                {desc}
            </p>
            {/* <TitleParagraphs title="Objectives of this Role" paragraphs={objectives} />
            <TitleParagraphs title="Daily and Monthly Responsibilities" paragraphs={responsibilities} />
            <TitleParagraphs title="Skills and Qualifications" paragraphs={skills} />
            <p>Good Luck!</p>
            <p className={styles.team}>MW Recruitment team.</p> */}
            <p className={styles.team}>MW Recruitment team.</p>
            <Link to={`/set-your-information/${shareId}`}><SharedButton children="Get Started" /></Link>
        </div>
    );
}
export default GetStarted;
GetStarted.propTypes = {
    title: PropTypes.string,
    objectives: PropTypes.array,
    responsibilities: PropTypes.array,
    skills: PropTypes.array,
    hasVideo: PropTypes.bool,
};
