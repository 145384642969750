import React from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import { AppRoute } from "./index";
import Dashboard from "../../components/ui/molecules/Dashboard";
import ProtectedPanel from "../../components/hocs/ProtectedPanel.js";

export default function RoutesHOC() {
  const { isAuthenticated } = useSelector((state) => state.user);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/dashboard" />} />
      <Route
        path="/dashboard"
        exact={true}
        element={
          <ProtectedPanel>
            <Dashboard />
          </ProtectedPanel>
        }
      />
      <Route
        index
        path="/*"
        element={<AppRoute isAuthenticated={isAuthenticated} />}
      />
    </Routes>
  );
}
