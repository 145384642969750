import ApplicantCard from "../../atoms/ApplicantCard";
import SharedButton from "../../atoms/SharedButton";
import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Star } from "../../../../assets/images/star.svg";
import { ReactComponent as GrayStar } from "../../../../assets/images/darkstar.svg";
import DeletePopup from "../../organisms/deletePopup";
import ArchivePopup from "../../organisms/archivePopup";
import { ReactComponent as Comment } from "../../../../assets/images/comment.svg";
import { ReactComponent as URL } from "../../../../assets/images/url.svg";
import Comments from "../../atoms/Comments";
import Carousel from "../Carousel";
import { getComments } from "../../../../redux/actions/commentsActions";

const items = [
  {
    id: 1,
    time: "4:35",
    question: "Question 1",
    description: "How do you handle pressure?",
  },
  {
    id: 2,
    time: "4:35",
    question: "Question 2",
    description:
      "What is the most interesting thing about you that the CV is not reflecting?",
  },
  {
    id: 3,
    time: "4:35",
    question: "Question 3",
    description: "Share stories about sources of motivation...",
  },
  {
    id: 4,
    time: "4:35",
    question: "Question 4",
    description: "Explain how you have worked with your weaknesses?",
  },
  {
    id: 5,
    time: "4:35",
    question: "Question 5",
    description: "What makes you the ideal candidate?",
  },
  {
    id: 6,
    time: "4:35",
    question: "Question 6",
    description: "How do you handle pressure?",
  },
  {
    id: 7,
    time: "4:35",
    question: "Question 7",
    description: "How do you handle pressure?",
  },
  {
    id: 8,
    time: "4:35",
    question: "Question 8",
    description:
      "What is the most interesting thing about you that the CV is not reflecting?",
  },
  {
    id: 9,
    time: "4:35",
    question: "Question 9",
    description: "Share stories about sources of motivation...",
  },
  {
    id: 10,
    time: "4:35",
    question: "Question 10",
    description: "Explain how you have worked with your weaknesses?",
  },
  {
    id: 11,
    time: "4:35",
    question: "Question 11",
    description: "What makes you the ideal candidate?",
  },
  {
    id: 12,
    time: "4:35",
    question: "Question 12",
    description: "How do you handle pressure?",
  },
];

export default function Applicant() {
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/wizr-connect/schedule-meeting`;
    navigate(path);
  };
  const titles = [
    "Country, City:",
    "Email",
    "Phone",
    "Resume submitted:",
    "Personal message",
    "Show Room avaliable:",
  ];
  const desc = [
    "Norway, Oslo",
    "johnsmith@youremail.com",
    "+001 234 567 980",
    "Yes",
    "Yes",
    "Yes",
  ];

  const [extraClass, setExtraClass] = useState("close");
  const [videoItem, setActiveVideo] = useState(items[0]);

  const openHandler = () => {
      setExtraClass("");
  };

  const sidebarCloseHandler = () => {
      setExtraClass("close");
  };

  const dispatch = useDispatch();
  useEffect(() => dispatch(getComments()), []);
  const comments = useSelector(state => state.comments.comments);
  const noOfComments = comments.length;

  return (
    <>
    <div className={styles.applicant}>
      <div className={styles.wrapper}>
        <div className={styles.info}>
          <h1>Ana Smith</h1>
          <ApplicantCard
            extraClass="applicantCard"
            titlesList={titles}
            descList={desc}
          />
        </div>
        <SharedButton clickEvent={routeChange} extraClass="grayBtn1">
          Schedule Meeting
        </SharedButton>
      </div>

      <div className={styles.data}>
        <h1>Company Review</h1>
        <div className={styles.icons}>
          <Star />
          <Star />
          <Star />
          <Star />
          <Star />
          <GrayStar />
          <GrayStar />
        </div>
        <div className={styles.buttons}>
          <SharedButton extraClass="orange1">Accept</SharedButton>
          <DeletePopup />
          <SharedButton extraClass="grayBtn1">Reject</SharedButton>
          <ArchivePopup />
        </div>
        <button className={styles.comment} onClick={openHandler} >
          <Comment />
          <p className={styles.commentPart}>Make a comment ({noOfComments})</p>
        </button>
        <Comments
            close={sidebarCloseHandler}
            extraClass={extraClass}
            comments={comments}
            noOfComments={noOfComments}
          />
        <div className={styles.url}>
          <URL />
          <p>Invite for comments</p>
        </div>
      </div>
    </div>
    <Carousel items={items} setActiveVideo={setActiveVideo} />
    </>
  );
}
