import React, { useState, useRef } from "react";
import styles from "./style.module.scss";
import PropTypes from "prop-types";
import { ReactComponent as Plus } from "../../../../assets/images/plus.svg";
import { ReactComponent as Edit } from "../../../../assets/images/edit.svg";
import useClickOutside from "../../../../settings/helpers/useClickOutside";
import { successToast, errorToast } from "../../atoms/Toasts/toasts";

function QuestionCards({
  question,
  description,
  addCard,
  job,
  index,
  onDelete,
  onEdit,
  addNewQuestionCard,
  newQuestion,
  removeQuestion,
  success
}) {
  const [activeMenu, setActiveMenu] = useState(false);
  const [options, showOptions] = useState(false);
  const [hover, setHover] = useState();
  const [hoverRetake, setHoverRetake] = useState();

  const handleMouseIn = () => {
    setHover(true);

  };
  const handleMouseRetake = () => {
    setHoverRetake(true)
  }

  const handleMouseOut = () => {
    setHover(false);
    setHoverRetake(false)
  };


  const openMenu = (e) => {
    e.preventDefault();
    setActiveMenu(index === activeMenu ? false : index);
  };

  const teamRef = useRef(null);
  useClickOutside(teamRef, () => setActiveMenu(false));

  const showEditOptions = () => {
    showOptions((options) => !options);
    
  };

  const handleChange = (e) => {
    e.preventDefault();
    let value = e.currentTarget.textContent;
    question.text = value;
  };

  const handleSave = (value) => {
    if (!value?._id) {
      value.job_id = job?.jobId
      value.owner = job?.owner
      newQuestion(value);

      if (success == undefined) {
        // successToast("new question successfully created");
        handleClose();
      } else errorToast("an error occured, please try again");
      //todo :> handle addition if newquestion fails
    } else onEdit(value, value?._id);
    showEditOptions();
  };

  const handleClose = () => {
    removeQuestion({ _id: "1" });
    addNewQuestionCard();
  };

  const contentEditable = (id) => {
    if (id && options) {
      return options.toString();
    }
    if (!id && !options) {
      return "true";
    }
    return "false";
  };

  const handleTimer = (time, key, questionId) => {
    if(!questionId){
      question.timer = time
      handleMouseOut()
      successToast('Timer successfully set')
      return
    }
    question.timer = time
    onEdit(question, question?._id)
    removeQuestion();
  }

  const handleRetakes = (retakes, key, questionId) => {  
    if(!questionId){
      if(retakes === 'unlimited'){
        retakes = 0
      }
      question.retakes = retakes
      handleMouseOut()
      successToast('Retakes successfully set')
      return
    }
    if(retakes === 'unlimited'){
      retakes = 0
    }
    question.retakes = retakes
    onEdit(question, question?._id)
    removeQuestion();
  }

  const handleEnterKey =(event)=> {
    if (event.keyCode === 13) {
      handleSave(question)
    }
  }

  const handleEditQuestion = (event) => {
    showEditOptions(); 
    openMenu(event);
  }

  const handleDeleteQuestion = (event) => {
    openMenu(event);
    onDelete()
  }

  const timerValues = [1, 2, 3, 4, 5]
  const retakeValues = [1, 2, 3, 4, 'unlimited']
  return addCard ? (
    <div className={styles.questionContainer}>
      <div className={`${styles.addQuestion} ${styles.plusIcon}`}>
        <div className={styles.plusIcon} onClick={addNewQuestionCard}>
          <Plus />
        </div>
        <p>{description}</p>
      </div>
    </div>
  ) : (
    <div className={styles.questionContainer} ref={teamRef}>
      <div className={styles.addQuestion}>
        <h3>Question {index + 1}</h3>
        <div>
        <span
            contentEditable={contentEditable(question?._id)}
            suppressContentEditableWarning={true}
            onInput={handleChange}
            onKeyDown={handleEnterKey}
          >
            <pre></pre>
            {question?.text || ""}
          </span>

            {(options || question?._id === undefined) && (
              <div className={styles.buttons}>
                <button className={styles.closeBtn} onClick={question?.job_id ? showEditOptions : handleClose}>Cancel</button>
                <button className={styles.saveBtn} onClick={()=>handleSave(question)}>Save</button>
              </div>
            )}
        </div>
      </div>
      <div
        aria-hidden={true}
        role="button"
        className={styles.edit}
        onClick={(e) => openMenu(e)}
      >
        <Edit />
        <button>Edit actions</button>
      </div>
      {index === activeMenu && (
        <div className={`${styles.dropdown} ${styles.showDropdown}`}>
          <button onClick={handleEditQuestion}>Edit question</button>
          <button onMouseOver={handleMouseIn} onMouseOut={handleMouseOut}
  
          >Set timer
           {hover? <div className={`${styles.dropdown} ${styles.showDropdown}  ${styles.timer}`}>
           {timerValues.map((item, key)=> {
             return <button onClick={()=>handleTimer(item, key, question?._id)}>
             {item} minutes
           </button>
           }) }
          </div>: <></>}
          </button>

          <button onMouseOver={handleMouseRetake} onMouseOut={handleMouseOut}>Number of retakes
          {hoverRetake ? <div className={`${styles.dropdown} ${styles.showDropdown} ${styles.retakes}`}>
           {retakeValues.map((item, key)=> {
             return <button  onClick={()=>handleRetakes(item, key, question?._id)} >
             {item} retakes
           </button>
           }) }
          </div>: <></>}
          </button>
          <button onClick={handleDeleteQuestion}>Delete</button>
        </div>
      )}
    </div>
  );
}

QuestionCards.propTypes = {
  question: PropTypes.object,
  description: PropTypes.string,
  add: PropTypes.bool,
  index: PropTypes.number,
};
export default QuestionCards;
