import React from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";
import { ReactComponent as GridLogo } from "../../../../assets/images/grid-i.svg";
import { ReactComponent as RowLogo } from "../../../../assets/images/row-i.svg";
import SearchBar from "../SearchBar";

function SearchComponent({ type, title, handleGridView, handleRowsView }) {

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.heading}>
                    <div className={styles.titleSearch}>
                        <div className={styles.title}>
                            <h1>{title}</h1>
                        </div>
                        <SearchBar extraClass="applicants-search" />
                    </div>
                    <div className={styles.buttons}>
                        <button className={`${type === "grid" && styles.active}`} onClick={handleGridView}><GridLogo /></button>
                        <button className={`${type === "row" && styles.active}`} onClick={handleRowsView}><RowLogo /></button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SearchComponent;
SearchComponent.propTypes = {
    title: PropTypes.string,
    image: PropTypes.bool,
    list: PropTypes.array,
};
