import { SHARES } from "../actions/actionTypes";

const INITIAL_STATE = {
    loading: false,
    shares: [],
    error: null,
    sharedJob: {}
}

const sharesReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        case SHARES.REQUEST:
            return {
                ...state,
                loading: true
            };
        case SHARES.SUCCESS: 
            return {
                ...state,
                loading: false,
                shares: action.payload
            };
        case SHARES.SHARE_SUCCESS: 
            return {
                ...state,
                loading: false,
                sharedJob: action.payload
            };
        case SHARES.ERROR: 
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state
    }
}

export default sharesReducer;
