import { SHARES } from "../actionTypes" ;

export const getSharesRequest = () => ({
    type: SHARES.REQUEST,
});

export const getSharesSuccess = payload => ({
    type: SHARES.SUCCESS,
    payload
})
export const getJobSharesSuccess = payload => ({
    type: SHARES.SHARE_SUCCESS,
    payload
})

export const getSharesError = error => ({
    type: SHARES.ERROR,
    error
})

export const createUserInfo = payload => ({
    type: SHARES.CREATE_USER_INFO_REQUEST,
    payload
})
