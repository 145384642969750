import React from "react";
import styles from "./style.module.scss";
import { useSelector } from "react-redux";
import { ReactComponent as Tick } from "../../../../assets/images/tickIcon.svg";
function MainPopup() {
  const title = useSelector(state => state.shares.sharedJob.job?.title);
  const desc = useSelector(state => state.shares.sharedJob.job?.description);
  const location = useSelector(state => state.shares.sharedJob.job?.location);

  return (
    <div className={styles.mainPopup}>
      <div className={styles.content}>
        <Tick className={styles.tick} />
        <h1>You’re all done</h1>
        <h1 className={styles.white}>
          {location} Interview - <span>{title}</span>
        </h1>
        <h3>Master WiZR, Norway, Oslo</h3>
        <p>
          {desc}
        </p>
        <a
          className={styles.weblink}
          target="_blank"
          href="https://www.masterwizr.com/" rel="noreferrer"
        >
          Visit Website
        </a>
      </div>
    </div>
  );
}


export default  MainPopup;