import GetStartedDash from "../molecules/GetStarted";
import {objectives,responsibilities,skills} from ".././../../constants/GetStarted";

function GetStarted() {
  return (
    <>
      <GetStartedDash title="Sales Manager" hasVideo objectives={objectives} responsibilities={responsibilities} skills={skills}/>
    </>
  );
}

export default GetStarted;
