import { USER } from "../actions/actionTypes";
import {authenticateUser} from "../../utils/api/authHandler";

const tokenValidation = authenticateUser();

const INITIAL_STATE = {
    email: '',
    telephone: '',
    iss: '',
    wizr_user_id: '',
    fullName: '',
    _id: '',
    isAuthenticated: tokenValidation[0]
}

const userReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        case USER.ADD:
            return {
                ...state,
                email: action.payload.email,
                telephone: action.payload.telephone,
                iss: action.payload.iss,
                wizr_user_id: action.payload.wizr_user_id,
                _id: action.payload._id,
                fullName: action.payload.fullname,
                isAuthenticated: true
            };
        default:
            return state
    }
}

export default userReducer;
