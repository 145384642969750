import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./style.module.scss";
import VideoContent from "../videoContent";
import SingleInput from "../../atoms/SingleInput";
import Button from "../../atoms/SharedButton";
import { createJobReducer } from "../../../../redux/actions/job.actions";
import { addLocalFileName } from "../../../../redux/actions/actionCreators/uploadFileActionCreators";
import { setVideoURL } from "../../../../redux/actions/job.actions";
import { authenticateUser } from "../../../../utils/api/authHandler";
import { addUser } from "../../../../redux/actions/user.actions";
import { successToast } from "../../atoms/Toasts/toasts";
import Loader from "../../atoms/Loader";

function useForm(initialState = {}) {
  const [values, setValues] = useState(initialState);

  const changeHandler = (e) => {
    e.preventDefault();
    const newValues = { ...values, [e.target.name]: e.target.value };
    setValues(newValues);
  };

  return { values, changeHandler };
}

export function InterviewSetup1(props) {
  const listitems = [
    "Welcome Greeting",
    "About 50+",
    "Videos made simple",
    "Why Show Rooms",
  ];

  const [videoUploadUri, setVideoUploadDataUri] = useState(false);
  const [source, setSource] = React.useState('');

  const title = useSelector((state) => state.job.jobTitle);
  const name = useSelector((state) => state.job.companyName);
  const video = useSelector((state) => state.job.videoUrl);
  const desc = useSelector((state) => state.job.description);
  const isFormValid = useSelector((state) => state.job.isFormValid);
  const jobId = useSelector((state) => state.job._id);
  const loading = useSelector((state) => state.fileUpload.loading);
  const localSrc = useSelector((state) => state.fileUpload.localSource);
  

  let initialState = {
    jobTitle: title || "",
    companyName: name || "",
    description: desc || "",
    videoUrl: video || '',
    localSrc: localSrc || ''
  };

  let { values, changeHandler } = useForm(initialState);


  const dispatch = useDispatch();

  useEffect(() => {
    document.getElementsByName("videoUrl").value = video;
    values = { ...values, videoUrl: video };
  }, [video]);

  useEffect(() => {
    if(document.querySelector("button.mainplaybtn")) {
      const allPlayersBtns = document.querySelector("button.mainplaybtn");
        !allPlayersBtns.classList.value.includes(`${styles.playBtn}`) && allPlayersBtns.classList.add(`${styles.playBtn}`);
  }
}, [video]);

  const sessionData = authenticateUser();
  const wizr_user_id = useSelector((state) => state.user.wizr_user_id);
  useEffect(() => {
    if (wizr_user_id === "") {
      sessionData[0] && dispatch(addUser(sessionData[1]));
    }
  });

  useEffect(() => {
    dispatch({ type: createJobReducer(), payload: values });
  }, [values]);

  const changeVideoHandler = (e) => {
    e.preventDefault();
    e.target.videoUrl = e.target.value;
    dispatch(setVideoURL(e.target.value));
  };

  useEffect(() => {
    dispatch({ type: createJobReducer(), payload: values });
  }, [values]);
  
  useEffect(()=>{
    dispatch(addLocalFileName(source?.name));
  }, [source])

  function isNullEmptyBlank(str = "") {
    return str === null || str.trim().length === 0;
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSource(file)
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      let base64data = reader.result;
      let video = document.getElementsByTagName("video")[0];
      video.src = base64data;
      setVideoUploadDataUri(base64data);
      successToast('video successfully uploaded')
    };

  };

  const { jobTitle, companyName, videoUrl, description } = values;

  return (
    <>
      <div className={styles.form}>
        <div className={styles.loader}>
       {(loading && !jobId && isFormValid) && <Loader/>}
        </div>
        <div className={styles.formHolder}>
          <h2>Fill the form!</h2>
          <label htmlFor="interview">Interview title</label>
          <SingleInput
            required={true}
            placeholder="Sales Manager"
            id="interview"
            name="jobTitle"
            value={jobTitle}
            handleChange={changeHandler}
            errorMsg={
              !isFormValid && isNullEmptyBlank(jobTitle)
                ? "Can not be blank"
                : ""
            }
          />
          <label htmlFor="company">Company</label>
          <SingleInput
            required={true}
            placeholder="Master WiZR"
            id="company"
            name="companyName"
            value={companyName}
            handleChange={changeHandler}
            errorMsg={
              !isFormValid && isNullEmptyBlank(companyName)
                ? "Can not be blank"
                : ""
            }
          />
          <div className={styles.videointro}>
            <div className={styles.switch}>
              <span>Record my own</span>
              <div className={styles.switchbtn}>
                <div className={styles.circle}></div>
              </div>
            </div>
            <label htmlFor="video">Video Intro</label>
          </div>
          <SingleInput
            placeholder="URL video link"
            id="video"
            name="videoUrl"
            value={video || source?.name}
            handleChange={changeVideoHandler}
            errorMsg={
              !isFormValid && isNullEmptyBlank(video) ? "Can not be blank" : ""
            }
          />
          <label htmlFor="description">Description</label>
          <textarea
            required
            placeholder="Description of job application"
            rows="5"
            name="description"
            onChange={changeHandler}
            value={description}
          ></textarea>
          <span className={styles.error}>
            {!isFormValid && isNullEmptyBlank(description)
              ? "Can not be blank"
              : ""}
          </span>
        </div>
        <VideoContent
          extraClass="unorderedList"
          unorderedList
          list={listitems}
          extraClass1="withoutBanner1"
          listTitle="Record a personal video intro"
          subtitle="Opportunity to explain the next steps"
          videoUrl={video ? video : videoUploadUri}
          handleFileChange={handleFileChange}
        />
      </div>
    
     {!loading && <Button
        extraClass="next-button"
        clickEvent={() => {
          props.handleNext(values, video, videoUploadUri);
        }}
      >
        Next Step
      </Button>
      }
    </>
  );
}

export default InterviewSetup1;
