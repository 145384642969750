import React from "react";

export default function index() {
  return (
    <svg
      width="16"
      height="26"
      viewBox="0 0 16 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75146 20.5C3.55946 20.5 3.36746 20.427 3.22146 20.28C2.92846 19.987 2.92846 19.513 3.22146 19.22L19.2205 3.21999C19.5135 2.92699 19.9875 2.92699 20.2805 3.21999C20.5735 3.51299 20.5735 3.98699 20.2805 4.27999L4.28146 20.28C4.13546 20.427 3.94346 20.5 3.75146 20.5Z"
        fill="#C1C1C1"
      />
      <g clipPath="url(#clip0_267_6238)">
        <path
          d="M16 13.4107C16 12.9145 15.619 12.5176 15.1429 12.5176C14.6667 12.5176 14.2857 12.9145 14.2857 13.4107C14.2857 16.9832 11.5238 19.8611 8.09524 19.8611C4.66667 19.8611 1.80952 16.9832 1.80952 13.4107C1.80952 12.9145 1.33333 12.5176 0.857143 12.5176C0.380952 12.5176 0 12.9145 0 13.4107C0 17.6779 3.04762 21.2504 7.14286 21.7466V24.2275H3.90476C3.42857 24.2275 3.04762 24.6244 3.04762 25.1206C3.04762 25.6168 3.42857 26.0138 3.90476 26.0138H12.0952C12.5714 26.0138 12.9524 25.6168 12.9524 25.1206C12.9524 24.6244 12.5714 24.2275 12.0952 24.2275H8.85714V21.7466C12.9524 21.2504 16 17.6779 16 13.4107Z"
          fill="#C1C1C1"
        />
        <path
          d="M8.03637 0C5.27446 0 3.08398 2.28244 3.08398 5.16031V13.4962C3.08398 16.2748 5.27446 18.6565 7.94113 18.6565C10.6078 18.6565 12.8935 16.374 12.8935 13.5954V5.16031C12.8935 2.28244 10.703 0 8.03637 0Z"
          fill="#C1C1C1"
        />
      </g>
      <defs>
        {/* <clipPath id="clip0_267_6238">
          <rect width="16" height="26" fill="white" />
        </clipPath> */}
      </defs>
    </svg>
  );
}
