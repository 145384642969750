import requestServer from "../../utils/api/index";
import {saveJobResponseInit, saveJobResponseSuccess, saveJobResponseError, updateJobResponseInit, updateJobResponseSuccess, updateJobResponseError, getJobResponseInit, getJobResponseSuccess, getJobResponseError} from '../actions/actionCreators/jobResponseActionCreators';

export const saveJobResponse = (data, nextPage, resume_url) => async (dispatch) => {
  dispatch(saveJobResponseInit());
  try {
    const response = await requestServer('POST', '/submission', `/createSubmission`, '', {
      user: {...data},
      resume_url
    });
  
    dispatch(saveJobResponseSuccess(response.data));
    nextPage && nextPage(response.data.data.submission._id)
} catch (error) {
  dispatch(saveJobResponseError(error));
}
}

export const updateJobResponse = (id, data, callback) => async (dispatch) => {
  dispatch(updateJobResponseInit());
  try{
    const response = await requestServer('PUT', '/submission/updateSubmission',`/${id}`,'',
      data
    );
    callback && callback();
    dispatch(updateJobResponseSuccess({...response.data, questionId: data.questionId}));
    // nextPage && nextPage(response.data.data.submission._id)

  }catch(error){
    dispatch(updateJobResponseError(error));
  }
}

export const getJobResponse = (submissionId) => async dispatch => {
  dispatch(getJobResponseInit());
  try {
      const response = await requestServer(
        

          "GET", `submission/getSubmission/${submissionId}`, ""
      );
      if (response.status === 200){
          dispatch(getJobResponseSuccess(response.data.data));
      }
      else {
          dispatch(getJobResponseError(response.data.message));
      }

  } catch (error) {
      dispatch(getJobResponseError(error));
  }
};


