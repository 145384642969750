import { UPLOAD_FILE } from "../actions/actionTypes";

const INITIAL_STATE = {
  loading: false,
  error: false,
  data: null,
  localSource: ''
};

const fileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPLOAD_FILE.INIT:
      return {
        ...state,
        loading: true,
      };
    case UPLOAD_FILE.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case UPLOAD_FILE.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPLOAD_FILE.LOCAL_SOURCE:
    return {
      ...state,
      loading: false,
      localSource: action.payload,
    };

    default:
      return state;
  }
};

export default fileReducer;
