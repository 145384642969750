import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";
import MWMenu from "../../ui/molecules/UniversalMenu/MW-Menu";
import TopNavigation from "../../ui/molecules/TopNavigation";
import UniversalMenuComponent from "../../ui/molecules/UniversalMenu";

function ProtectedPanel({ children }) {
  const [state, setState] = useState({ showMenu: true });
  const showUniversalMenu = () => {
    setState((oldState) => ({
      ...oldState,
      showMenu: !oldState.showMenu,
    }));
  };
  return (
    <div className={styles.protectedPanel}>
      <TopNavigation />
      <div className={styles.universalIcon}>
        <MWMenu onClick={showUniversalMenu} />
      </div>
      <UniversalMenuComponent
        showMenu={state.showMenu}
        handleShowMenu={showUniversalMenu}
      />
      {children}
    </div>
  );
}
export default ProtectedPanel;
ProtectedPanel.propTypes = {
  children: PropTypes.node,
  leftMenu: PropTypes.bool,
};
