import React, { useEffect } from "react";
import MyVideo from "../molecules/MyVideoDashboard";
import { useParams, useNavigate } from "react-router-dom";
import { Question } from "../../../constants/Question";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import Carousel from "../molecules/Carousel";
import DashboardNavigation from "../molecules/DashboardNavigation";
import { getShareJobData } from "../../../redux/actions/sharesActions";
import { getJobResponse } from "../../../redux/actions/jobResponseActions";
import PostFetchLoading from "../atoms/PostFetchLoading";

function MyVideoDashboard() {
  const [tempVideo, setTempVideo] = useState({});
  const [videoItem, setActiveVideo] = useState(null);
  const [submissionLoading, setSubmissionLoading] = useState("Fetching your previous submissions");
  const [currentVideoCount, setCurrentVideoCount] = useState(0);
  const dispatch = useDispatch();
  const shareId = useParams().id;
  const submissionId = useParams().submissionId;
  const data = useSelector((state) => state.jobResponses.data);
  const { loading, sharedJob } = useSelector((state) => state.shares);

  const navigate = useNavigate();

  const finishOnboarding = () => {
    return navigate("/done-popup", { replace: true });
  };

  useEffect(() => {
    if (shareId !== "") {
      dispatch(getShareJobData(shareId));
      dispatch(getJobResponse(submissionId));
    }
  }, [shareId, submissionId]);

  useEffect(() => {
    loading ? setSubmissionLoading("Fetching any previous submissions") : setTimeout(() => setSubmissionLoading(null), 2000);
    setActiveVideo(sharedJob.questions?.[currentVideoCount]);
  }, [loading]);

  return (
    <>
      {submissionLoading ? <PostFetchLoading message={submissionLoading} /> : null}
      <DashboardNavigation
        prevPage="/set-your-information"
        nextPage="/done-popup"
      />
      {!loading && sharedJob.questions?.length !== 0 && (
        <>
          <MyVideo
            name={Question.name}
            question={Question.question}
            firstdescription={Question.firstdescription}
            seconddescription={Question.seconddescription}
            videoItem={videoItem}
            setActiveVideo={setActiveVideo}
            tempVideo={tempVideo}
            setTempVideo={setTempVideo}
            items={sharedJob}
            submissionId={submissionId}
            submissionData={data}
            loading={loading}
            currentVideoCount={currentVideoCount}
            setCurrentVideoCount={setCurrentVideoCount}
            finishOnboarding={finishOnboarding}
            setSubmissionLoading={setSubmissionLoading}
          />
          <Carousel
            items={sharedJob?.questions}
            setActiveVideo={setActiveVideo}
            submissionData={data}
            tempVideo={tempVideo}
            setTempVideo={setTempVideo}
          />
        </>
      )}
    </>
  );
}

export default MyVideoDashboard;
