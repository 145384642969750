import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import styles from "./style.module.scss";
import { ReactComponent as Close2 } from "../../../../assets/images/close2.svg";
import Chat2 from "../../../../assets/images/chat2.jpg";
import Chat3 from "../../../../assets/images/chat3.jpg";
import Comment from "./Comment";
import { ReactComponent as Rightc } from "../../../../assets/images/right-arrowc.svg";
import { ReactComponent as Happyc } from "../../../../assets/images/happyc.svg";
import { ReactComponent as ArrownDown } from "../../../../assets/images/arrow-down.svg";
import { addComment, editComment, deleteComment } from "../../../../redux/actions/commentsActions";
import DeleteModal from "../../molecules/DeleteModal";

const Comments = ({ close, extraClass, comments, noOfComments, noOfReplies }) => {
    const [comment, setComment] = useState('');
    const [isOpenDropdown, setShowDropdown] = useState(null);
    const [isEditMode, setEditMode] = useState(false);
    const [activeCommentId, setActiveCommentId] = useState("");
    const [showModal, setShowModal] = useState(false);

    const validComment = value => value && value !== null && value.trim().length > 0;

    const closeHandler = (e) => {
        e.preventDefault();
        close();
    };

    const changeHandler = e => {
        e.preventDefault();
        setComment(e.target.value);
    }

    const dispatch = useDispatch();
    const clearTextField = () => {
        setComment("");
        document.getElementById('comment').value = "";
    }
    const performCreateOrEdit = () => {
        if(activeCommentId !== "" && isEditMode){
            dispatch(editComment(activeCommentId, comment));
            setEditMode(false);
        } else dispatch(addComment(comment));
    }

    const handleSubmitComment = () => {
        if(validComment(comment)){ 
            performCreateOrEdit();
            clearTextField();
        }
    }

    const handleEnterKey =(event)=> {
        if (event.keyCode === 13 && validComment(comment)) {
            performCreateOrEdit();
            clearTextField();
        }
    }

    const handleEditComment = (id, text) => {
        setShowDropdown(false);
        setComment(text);
        setActiveCommentId(id);
        setEditMode(true);
        document.getElementById("comment").focus();
    }

    const handleDeleteComment = id => {
        dispatch(deleteComment(id));
        setShowModal(false);
        setShowDropdown(false);
    }

    const openDropdown = i => setShowDropdown(i);
    const closeDropdown = () =>  setShowDropdown(null);
    const openDeleteModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    const [showReplies, setShowReplies] = useState(false);
    const toggleReplies = () => {
        setShowReplies(!showReplies);
    }

    return (
        <div className={`${styles.sidebar} ${styles[extraClass]}`}>
            <div className={styles.closeComments}>
                <p>{noOfComments} Comment{noOfComments === 0 ? '' : 's'}</p>
                <button className={styles.closen} onClick={closeHandler}><Close2 /></button>
            </div>
            {comments.map((item, i) => {
                // commented code awaiting feedback from Jone
                // const { replies } = item;
                // const noOfReplies = replies.length;
                    return(
                        <div className={styles.wrapper}>
                            <Comment 
                                myKey={i}
                                key={i} 
                                item={item} 
                                isOpenDropdown={isOpenDropdown}
                                handleEditComment={handleEditComment}
                                handleDeleteComment={handleDeleteComment}
                                openDeleteModal={openDeleteModal}
                                openDropdown={() => openDropdown(i)}
                                closeDropdown={closeDropdown}
                                showModal={showModal}
                                closeModal={closeModal}
                            />
                            {/* {noOfComments > 5 && <div className={`${styles.load} ${styles.load1}`}>
                                <div className={`${styles.load} ${styles.load2}`}>
                                    <div className={`${styles.load} ${styles.load3}`}>
                                        <p>Load previous comments</p>
                                    </div>
                                </div>
                                </div>
                            } */}
                            {/* {noOfReplies > 0 && <div className={styles.imgs}>
                                <img src={Chat2} alt="" />
                                <div className={styles.jd}><p>JD</p></div>
                                <img src={Chat3} alt="" />
                                <p className={styles.commentsPart}>{noOfReplies}&nbsp;comment{noOfReplies < 0 ? 's': ''}</p>
                                <p className={styles.date}>today, 10:08 AM</p>
                                <ArrownDown onClick={toggleReplies} /> 
                            </div>} */}
                            {/* {showReplies && 
                                <div className={styles.commentsRight}>
                                    {replies.map((item) => {
                                        return <Comment key={i} item={item} />;
                                    })}
                                </div>
                            } */}
                        </div>
                    );
                    
            })}
            <div className={styles.writeComment}>
                <div className={styles.icons}>
                    <Happyc fill="white" />
                    <input type="text" onChange={changeHandler} onKeyDown={handleEnterKey} id='comment' value={comment} />
                    <Rightc fill="#EC7600" onClick={handleSubmitComment} />
                </div>
            </div>
        </div>
    );
};

export default Comments;
Comments.propTypes = {
    close: PropTypes.func,
    extraClass: PropTypes.string
};