import React, {useState} from "react";
import Popup from "../atoms/Popup";
import InputButton from "../atoms/InputButton";
import TextLink from "../atoms/TextLink";
import PopupButton from "../atoms/Popup/PopupButton";

function InvitePopup() {
  const [popup, setPopup] = useState(false);

  const hidepopup = () => {
    setPopup(false);
  };

  const openpopup = () => {
    setPopup(true);
  };
  return (
    <>
      <PopupButton extraClass="orange1" title="Invite" openPopup={openpopup} />
      <Popup
        removePopup={hidepopup}
        title="Invite team to board"
        extraClass="withoutSpace"
        state={popup}
      >
        <h4>Add people</h4>
        <InputButton text="Send" />
        <TextLink
          title="Invite with link"
          paragraph="Anyone with the link can join as board member"
          link="Create Link"
        />
      </Popup>
    </>
  );
}

export default InvitePopup;
