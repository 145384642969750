import React, { useState } from "react";
import Popup from "../../atoms/Popup";
import styles from "./style.module.scss";
import TwoButtons from "../../atoms/TwoButtons";
import PopupButton from "../../atoms/Popup/PopupButton";
import PropTypes from "prop-types";

function DownloadPopup({ color }) {
  const [popup, setPopup] = useState(false);

  const hidePopup = () => {
    setPopup(false);
  };
  const openPopup = () => {
    setPopup(true);
  };
  return (
    <>
      <PopupButton title="Download" openPopup={openPopup} extraClass={color} />
      <Popup removePopup={hidePopup} title="Download" state={popup}>
        <h4>Quality</h4>
        <div className={styles.selectOptions}>
          <select>
            <option value>6.26 MB | 720 x 720</option>
            <option value="1">8.26 MB | 720 x 720</option>
            <option value="2">10.26 MB | 720 x 720</option>
          </select>
        </div>
        <div className={styles.originalSize}>
          <div>
            <h4>Original size</h4>
            <div>
              <p>6.26 MB | 720 x 720</p>
            </div>
          </div>
          <TwoButtons firsttext="Cancel" secondtext="Done" />
        </div>
      </Popup>
    </>
  );
}

DownloadPopup.propTypes = {
  color: PropTypes.string,
};

export default DownloadPopup;
