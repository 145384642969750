import React from "react";
import styles from "./style.module.scss";
import PropTypes from "prop-types";

function SharedButton({children, extraClass, clickEvent, type, disabled}) {
  return (
    <button
      onClick={clickEvent}
      className={`${styles.sharedButton} ${styles[extraClass]}`}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default SharedButton;
SharedButton.propTypes = {
  children: PropTypes.string,
  extraClass: PropTypes.string,
  clickEvent: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};
