import React from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";

function SingleInput({ label, value, placeholder, type, required, handleChange, name, errorMsg }) {
  return (
    <div className={styles.singleInput}>
       <label>{label}</label>
      <input type={type} placeholder={placeholder} required={required} value={value} name={name} onChange={handleChange} />
      <span>{errorMsg}</span>
    </div>
  );
}
export default SingleInput;
SingleInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.any,
};
