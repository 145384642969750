import React from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";

function OptionInput({ list, id, extraClass, onChange }) {
  return (
    <div
      className={`${styles.OptionInput} ${styles[extraClass]}`}
      onChange={e => onChange(e.target.value)}
    >
      {list.map((item, i) => {
        return (
          <div key={i}>
            <input type="radio" name={`group${id}`} value={item} />
            <label>{item}</label>
          </div>
        );
      })}
    </div>
  );
}
export default OptionInput;
OptionInput.propTypes = {
  id: PropTypes.number,
  list: PropTypes.array,
  extraClass: PropTypes.string,
  title: PropTypes.string,
  onChange: PropTypes.func,
};
