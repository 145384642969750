import React from "react";
import { Link } from "react-router-dom";
import styles from "./style.module.scss";
import ApplicantCard from "../../atoms/ApplicantCard";
import SharedButton from "../../atoms/SharedButton";
import InvitePopup from "../../organisms/invitePopup";
import RenamePopup from "../../organisms/renamePopup";

export default function AllApplicants() {
  const titles = ["Invited:", "To review: ", "Accepted:", "Rejected:"];
  const desc = ["0", "4", "2", "0"];

  const titles2 = ["Started:", "Responded:", "Start:", "End:"];
  const desc2 = [
    "6 applicants",
    "2 applicants (100%)",
    "2 March 2022",
    "No end date",
  ];
  return (
    <div className={styles.allaplicants}>
      <div className={styles.cardContainer}>
        <ApplicantCard descList={desc} titlesList={titles} />
        <ApplicantCard descList={desc2} titlesList={titles2} />
        <div className={styles.buttons}>
          <InvitePopup />
          <RenamePopup />
          <Link to="/interviewsteps">
            <SharedButton extraClass="grayBtn1">Create</SharedButton>
          </Link>
        </div>
      </div>
    </div>
  );
}
