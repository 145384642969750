import { COMMENTS } from "../actions/actionTypes";

const INITIAL_STATE = {
    loading: false,
    error: false,
    comments: [],
    message: '',
}

const commentsReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        case COMMENTS.FETCH_ALL_COMMENTS_REQUEST:
            return{
                ...state,
                loading: true
            }
            case COMMENTS.FETCH_ALL_COMMENTS_SUCCESS:
                return{
                    ...state,
                    loading: false,
                    comments: action.payload.data.comments.results,
                    message: action.payload.message
                }
            case COMMENTS.FETCH_ALL_COMMENTS_ERROR:
                return{
                    ...state,
                    loading: false,
                    error: action.error
                }
            case COMMENTS.ADD_COMMENTS_REQUEST:
                return{
                    ...state,
                    loading: true
                }
            case COMMENTS.ADD_COMMENT_SUCCESS:
                return{
                    ...state,
                    comments: [...state.comments, action.payload.data.comment],
                    message: action.payload.message
                }
            case COMMENTS.ADD_COMMENT_ERROR:
                return{
                    ...state,
                    loading: false,
                    error: true,
                    message: action.error
                }
            case COMMENTS.DELETE_COMMENT_REQUEST:
                return{
                    ...state,
                    loading: true
                }
            case COMMENTS.DELETE_COMMENT_SUCCESS:
                return{
                    ...state,
                    comments: state.comments.filter((item) => item._id !== action.payload.data.comment._id),
                    message: action.payload.message
                }
            case COMMENTS.DELETE_COMMENT_ERROR:
                return{
                    ...state,
                    loading: false,
                    error: true,
                    message: action
                }
            case COMMENTS.EDIT_COMMENT_REQUEST:
                return{
                    ...state,
                    loading: true
                }
            case COMMENTS.EDIT_COMMENT_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    error: false,
                    comments: state.comments.map(comment => comment._id === action.payload.data.comment._id ?
                        { ...comment, text: action.payload.data.comment.text } : 
                        comment
                    ),
                    message: action.payload.message
                };
            case COMMENTS.EDIT_COMMENT_ERROR:
                return{
                    ...state,
                    loading: false,
                    error: true,
                    message: action.error
                }
        default:
            return state
    }
}

export default commentsReducer;
