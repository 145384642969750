import requestServer from "../../utils/api/index";
import {
  saveFileInit,
  saveFileSuccess,
  saveFileError,
  addLocalFileName
} from "../actions/actionCreators/uploadFileActionCreators";

export const saveFile = (data, callBack) => async (dispatch) => {
  dispatch(saveFileInit());
  try {
    const response = await requestServer(
      "POST",
      "/file",
      `/uploadFile`,
      "",
      data
    );
    dispatch(saveFileSuccess(response.data));
    callBack && callBack(response.data.data.url);
  } catch (error) {
    dispatch(saveFileError(error));
  }
};

export const saveVideo = (data, callBack) => async (dispatch) => {
  dispatch(saveFileInit());

  try {
    const response = await requestServer(
      "POST",
      "/video",
      `/uploadVideo`,
      "",
      data
    );
    
    dispatch(saveFileSuccess(response.data));
    callBack && callBack(response.data.data.url);
    console.log(response.data.data.url, "After saving the video");
  } catch (error) {
    dispatch(saveFileError(error));
  }
};

export const localSource = (data) => {
  return async (dispatch) => {
    dispatch(addLocalFileName(data));
  };
};
