const axios = require('axios');
const baseUrl = 'http://localhost:3100/api';

export function postJob(payload){

    axios.post(`${baseUrl}/createJob`, {payload})
    .then(response => {
        console.log('------payload-----', payload)
        console.log('------response-----', response)
        window.location.href='/get-started';

    })
    .catch(error => {
        console.log('---error---', error)
    })
}
