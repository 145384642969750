import React from "react";
import styles from "./style.module.scss";
import Modal from "../../molecules/VideoRecords/Modal";
import TwoButtons from "../../atoms/TwoButtons";

export function DeleteModal({
    heading, 
    description, 
    handleDeleteComment, 
    handleCloseModal
}){
    return(
        <Modal>
            <div className={styles.container}>
                <div className={styles.header}>{heading}</div>
                <div className={styles.content}>
                    {description}
                    <TwoButtons 
                        firsttext="Cancel" 
                        secondtext="Delete" 
                        extraClass="lightGrayBtn"
                        handleFirstButtonClick={handleCloseModal}
                        handleSecondButtonClick={handleDeleteComment}
                    />
                </div>
                
            </div>
        </Modal>
    )
}

export default DeleteModal;
