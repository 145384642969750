import React from "react";
import styles from "./style.module.scss";

function MenuItem({icon, label}) {
  return (
    <div className={styles.wrapper}>
       <div className={styles.iconWrapper}>{icon}</div>
       <span>{label}</span>

    </div>
  );
}

export default MenuItem;
