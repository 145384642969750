import React from "react";
import styles from "./style.module.scss";
import { ReactComponent as SettingsIcon } from "../../../../assets/menu/settings2.svg";
import { ReactComponent as MenuIcon } from "../../../../assets/menu/menu.svg";
import { ReactComponent as ChatIcon } from "../../../../assets/menu/chat.svg";
import { ReactComponent as WizrIcon } from "../../../../assets/menu/wizr.svg";
import { ReactComponent as CloudIcon } from "../../../../assets/menu/cloud.svg";

function MenuNav({icon, label}) {
  return (
    <div className={styles.navWrapper}>
        <MenuIcon className={styles.icon} />
        <SettingsIcon className={styles.icon} />
        <WizrIcon className={styles.icon} />
        <CloudIcon className={styles.icon} />
        <ChatIcon className={styles.icon} />
    </div>
  );
}

export default MenuNav;
