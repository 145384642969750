import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import styles from "./style.module.scss";
import DeletePopup from "../../organisms/deletePopup";
import DownloadPopup from "../../organisms/DownloadPopup/index";
import SharePopup from "../../organisms/SharePopup/index";
import ArchivePopup from "../../organisms/archivePopup";
import RenamePopup from "../../organisms/renamePopup";
import { ReactComponent as Favourite } from "../../../../assets/images/favourite.svg";
import { ReactComponent as Folder } from "../../../../assets/images/folder.svg";
import image from "../../../../assets/images/video-card-1.webp";
import useClickOutside from "../../../../settings/helpers/useClickOutside"

function LibraryCard({ name, index, date, size, type, pathHandler, item }) {
  const [fav, setFav] = useState(false);
  const [activeMenu, setActiveMenu] = useState(false);
  const openMenu = e => {
    e.preventDefault();
    setActiveMenu(index === activeMenu ? false : index);
  };
  const teamRef = useRef(null);
  useClickOutside(teamRef, () => setActiveMenu(false));
  return (
    <div className={styles.videoCard}>
      <div
        className={styles.imagePart}
        onClick={() => pathHandler(index, type)} onKeyDown={() => pathHandler(index, type)} role="button" tabIndex={0}
      >
        {type === "file" ? <img src={image} alt="library" /> : <Folder />}
        <div className={styles.bottom}>
          {fav && <Favourite />}
          <p>{size} mb</p>
        </div>
      </div>
      <div className={styles.heading} ref={teamRef}>
        <span onClick={() => pathHandler(index, type)} onKeyDown={() => pathHandler(index, type)} role="button" tabIndex={0}>{name || item.title}</span>
        <button onClick={(e) => openMenu(e)}>
          <div></div>
          <div></div>
          <div></div>
        </button>
      </div>
      <p>
        {date || moment(item.createdAt).format("D MMM, yy")}
        &nbsp;at&nbsp;
        {moment(item.createdAt).format("HH:mm")}
      </p>

      {index === activeMenu && (
        <div className={`${styles.dropdown} ${activeMenu && styles.showDropdown}`}>
          <RenamePopup />
          <button className={styles.buttonHover} >Duplicate</button>
          <ArchivePopup />
          <SharePopup />
          <DownloadPopup />
          <DeletePopup />
          <button className={styles.buttonHover} onClick={() => setFav(!fav)}>Favourite</button>
        </div>
      )}
    </div>
  );
}
export default LibraryCard;
LibraryCard.propTypes = {
  name: PropTypes.string,
  index: PropTypes.number,
  date: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  dropdownItem: PropTypes.number,
  dropdown: PropTypes.func,
  listIndex: PropTypes.number,
  pathHandler: PropTypes.func
};
