import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import GridView from "../GridView";
import TableView from "../TableViewInterview";

const Items = ({ type, list }) => {
    const [selectedOption, setSelectedOption] = useState("All");
    const [filterArray, setFilterArray] = useState(list);
    const [asc, setAsc] = useState(false);
    
    const filteredData = (type) => {
    const dropdwonFilter = list.filter(data => {
      if (type.toLowerCase() === "all") {
        return data;
      } else if (
        data.status.toLowerCase() ===
        type.toLowerCase()
      ) {
        return data;
      }
      return null;
    });
    setFilterArray(dropdwonFilter);

  };

  const sortAtoZ = type => {
    let sortedArray = [...filterArray];
    setAsc(!asc);
    sortedArray.sort((a, b) => {
      const isReversed = asc ? -1 : 1;
      return isReversed * a[type].localeCompare(b[type]);
    });
    setFilterArray(sortedArray);
  };

  const filterSort = type => {
    if (type === "organiser") {
      sortAtoZ(type);
    }
    else {
      setSelectedOption(type);
      filteredData(type);
    }
    return filterArray;
  };

  return (
    <>
      {type === "row" ? (
        <TableView
          list={filterArray} handleSort={filterSort} selectedOption={selectedOption}
        />
      ) : (
        <GridView list={list} />
      )}
    </>
  );
};

export default Items;
Items.propTypes = {
  type: PropTypes.string,
  list: PropTypes.array,
  setFilterArrays: PropTypes.any,
};
