import React from "react";
import { Link } from "react-router-dom";
import styles from "./style.module.scss";
import PropTypes from "prop-types";
import SharedButton from "../../atoms/SharedButton";
import RecordsIcon from "../../../../assets/icons/Record";

function SharedBanner({ title, description, extraClass, extraClass1, clickEvent, button, extraBtn }) {
  return (
    <div className={styles.main}>
      <div className={`${styles.container} ${styles[extraClass1]}`}>
        <div className={`${styles.banner} ${styles[extraClass]}`}>
          <div className={styles.content}>
            <h2>{title}</h2>
            <p>{description}</p>
            {button && (
              <div className={styles.btnWrapper}>
              <SharedButton extraClass="recordbtn" clickEvent={clickEvent}> <RecordsIcon /> Start Recording</SharedButton>
              </div>
            )
            }
            {
              extraBtn && <div className={styles.extraBtn}>
                <Link to="/interviewsteps"><SharedButton>Create Job Post</SharedButton></Link>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default SharedBanner;
SharedBanner.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  extraClass: PropTypes.string,
  extraClass1: PropTypes.string,
  clickEvent: PropTypes.func,
  button: PropTypes.bool,
};
