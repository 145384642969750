import image1 from "../assets/images/video-card-1.webp";
export const personalLibraryData = [
  {
    title: "Videohead show",
    date: "27 May 2021",
    image: image1,
    time: "4:35",
  },
  {
    title: "Videohead show",
    date: "27 May 2021",
    image: image1,
    time: "4:35",
  },
  {
    title: "Videohead show",
    date: "27 May 2021",
    image: image1,
    time: "4:35",
  },
  {
    title: "Videohead show",
    date: "27 May 2021",
    image: image1,
    time: "4:35",
  },
  {
    title: "Videohead show",
    date: "27 May 2021",
    image: image1,
    time: "4:35",
  },
  {
    title: "Videohead show",
    date: "27 May 2021",
    image: image1,
    time: "4:35",
  },
  {
    title: "Videohead show",
    date: "27 May 2021",
    image: image1,
    time: "4:35",
  },
  {
    title: "Videohead show",
    date: "27 May 2021",
    image: image1,
    time: "4:35",
  },
  {
    title: "Videohead show",
    date: "27 May 2021",
    image: image1,
    time: "4:35",
  },
  {
    title: "Videohead show",
    date: "27 May 2021",
    image: image1,
    time: "4:35",
  },
];
