import React, {useEffect, useState} from "react";
import style from "./styles.module.scss";
import placeholder from "../../../../assets/images/create.png";
import { VideoPlayer } from "@master-wizr/video-player";

export default function index({ items, item, setActiveVideo, key, index, submissionData, tempVideo, setTempVideo }) {

  const handleActive = (id) => {
    setActiveVideo(items[id]);
  };

  return (
    <div
      className={style.carouselWraper}
      onClick={() => handleActive(index)}
      onKeyDown={() => handleActive(index)}
      role="button"
      tabIndex={0}
    >
      <div className={style.img} onClick={(e) => e.preventDefault()}>
        <img src={tempVideo[item._id]?.video_url ? "https://i.imgur.com/slEYSsK.png" : placeholder || item.img} alt="img" />
        <span>{item?.videoUrl ? item.timer : '0:00'}</span>
      </div>
      <div className={style.desc}>
        <h2>Question {index +1}</h2>
        <p>{item.text}</p>
      </div>
    </div>
  );
}
