import React, {useState} from "react";
import Popup from "../atoms/Popup";
import TwoButtons from "../atoms/TwoButtons";
import PopupButton from "../atoms/Popup/PopupButton";

function ArchivePopup() {
  const [popup, setPopup] = useState(false);

  const hidepopup = () => {
    setPopup(false);
  };
  const openpopup = () => {
    setPopup(true);
  };
  return (
    <>
      <PopupButton
        extraClass="grayBtn1"
        title="Archive"
        openPopup={openpopup}
      />
      <Popup removePopup={hidepopup} title="Archive" state={popup}>
        <p>
          When you click to Archive option, you immediately save this video to
          your Archive folder. By hitting done you save this video to
          favourites.
        </p>
        <TwoButtons firsttext="Cancel" secondtext="Done"  extraClass="grayBtn"/>
      </Popup>
    </>
  );
}

export default ArchivePopup;