import React, { useState } from "react";
import Popup from "../../atoms/Popup";
import styles from "./style.module.scss";
import { ReactComponent as Linkedin } from "../../../../assets/images/linkedin-logo.svg";
import { ReactComponent as Fb } from "../../../../assets/images/fb-logo.svg";
import { ReactComponent as Whatsapp } from "../../../../assets/images/whatsapp-logo.svg";
import { ReactComponent as Twitter } from "../../../../assets/images/twitter logo.svg";
import InputButton from "../../atoms/InputButton";
import TextLink from "../../atoms/TextLink";
import PopupButton from "../../atoms/Popup/PopupButton";
import PropTypes from "prop-types";

function SharePopup({ color }) {
  const socialIcons = [<Fb />, <Twitter />, <Linkedin />, <Whatsapp />];
  const [popup, setPopup] = useState(false);

  const hidePopup = () => {
    setPopup(false);
  };

  const openPopup = () => {
    setPopup(true);
  };

  return (
    <>
      <PopupButton title="Share" openPopup={openPopup} extraClass={[color]} />
      <Popup
        removePopup={hidePopup}
        title="Share"
        extraClass={color}
        state={popup}
      >
        <h4>Add people</h4>
        <InputButton text="Share" />
        <h4>Or share this via</h4>
        <div className={styles.icons}>
          {socialIcons.map((line, i) => {
            return (
              <div key={i} className={styles.iconBg}>
                {line}
              </div>
            );
          })}
        </div>
        <TextLink
          title="Get Embedded Link"
          paragraph="Anyone with the link can view"
          link="Copy Link"
          icon
        />
      </Popup>
    </>
  );
}

SharePopup.propTypes = {
  color: PropTypes.string,
};

export default SharePopup;
