import React from "react";
import styles from "./style.module.scss";
import PropTypes from "prop-types";
import { ReactComponent as Close } from "../../../../assets/images/close.svg";

function Popup({ title, children, removePopup, extraClass, state }) {
  return (
    <>
      {state ? (
        <div className={`${styles.sharedModal} ${styles[extraClass]}`}>
          <div className={styles.modal}>
            <div className={styles.heading}>
              <h3>{title}</h3>
              <Close onClick={removePopup} />
            </div>
            <div className={`${styles.content} ${styles[extraClass]}`}>{children}</div>
          </div>
        </div>
      ) : null}
    </>
  );
}
export default Popup;
Popup.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  description: PropTypes.string,
  image: PropTypes.element,
  removePopup: PropTypes.func,
  extraClass: PropTypes.string,
  state: PropTypes.bool
};
