import React from "react";
import { Link } from "react-router-dom";
import styles from "./style.module.scss";
import PropTypes from "prop-types";
export default function DashboardCard({ title, subtitle, extraClass, linkTo }) {
  return (
    <div className={`${styles.cardContainer} ${styles[extraClass]}`}>
      {
        linkTo ? <Link to={linkTo}>
        <h3>{title}</h3>
        <h2>{subtitle}</h2>
      </Link> : <>
        <h3>{title}</h3>
        <h2>{subtitle}</h2>
      </>
      }
    </div>
  );
}

DashboardCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  extraClass: PropTypes.string,
};
