import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import styles from "./style.module.scss";
import Chat2 from "../../../../assets/images/chat2.jpg";
import DropdownMenu from "../DropdownMenu";
import {ReactComponent as Dots} from "../../../../assets/images/dots.svg";
import DeleteModal from "../../molecules/DeleteModal";

const Comments = ({
    item, 
    myKey,
    notAvailable, 
    isOpenDropdown, 
    handleEditComment, 
    openDeleteModal, 
    openDropdown, 
    closeDropdown,
    showModal,
    closeModal,
    handleDeleteComment
}) => {

    const { created_at, image, text} = item;
    const timeStamp = moment(created_at).format("HH:mm A");

    return (
        <div className={`${styles.firstChat} ${notAvailable && styles.bigChat}`}>
            {item.online ?
                <div className={styles.imgStatus}>
                    <img src={image || Chat2} alt="" />
                    <div className={`${styles.circle} ${styles.on}`}></div>
                </div> : notAvailable ? <img src={item.image} className={styles.imgBig} alt="" /> :
                    <div className={styles.imgStatus}>
                        <img src={image || Chat2} alt="" />
                        <div className={`${styles.circle} ${styles.off}`}></div>
                    </div>
            }
            <div className={styles.nameDescription}>
                <div className={styles.infoIcons}>
                    <div className={styles.nameDate}>
                        <p>{item.name || 'admin'}</p>
                        <span>•</span>
                        <p className={styles.date}>{timeStamp}</p>
                    </div>
                    {item.icons ? <div className={styles.icons}>👏 👀 1</div> : null}
                </div>
                <p className={styles.desc}>{text}</p>
                <p>{item.seconddescription}</p>
            </div>
            <Dots 
                className={styles.dots}
                onClick={openDropdown} 
            />
            {
                isOpenDropdown === myKey && <DropdownMenu 
                onMouseLeave={closeDropdown}
            >
                <div role="button" className={styles.buttonDropdown} onClick={() => handleEditComment(item._id, item.text)}>Edit</div>
                <div role="button" className={styles.buttonDropdown}  onClick={openDeleteModal} >Delete</div>
                {showModal && <DeleteModal
                    heading="Delete comment"
                    description="Are you sure you want to delete this comment?"
                    handleCloseModal={closeModal}
                    handleDeleteComment={() => handleDeleteComment(item._id)}
                />}
            </DropdownMenu>

            }
        </div>
    );
};

export default Comments;
Comments.propTypes = {
    item: PropTypes.array,
    notAvailable: PropTypes.bool
};