import React from "react";
import { Routes, Route } from "react-router-dom";
import appRoutes from "./pages";

const AppRoute = ({ isAuthenticated }) => {
  if (!isAuthenticated) {
    window.location.href = `${process.env.REACT_APP_LOGIN_URL}/free-trial-log-in?_rdr=${process.env.REACT_APP_WiZR_INTERVIEW_URL}/dashboard`;
  } else {
    return (
      <Routes>
        {appRoutes.map((r, i) => {
          return (
            <Route
              key={r.id}
              path={r.pathname}
              exact={r.exact}
              element={
                <r.layout>
                  <r.element />
                </r.layout>
              }
              render={(props) => <r.main {...props} />}
            />
          );
        })}
      </Routes>
    );
  }
  return <React.Fragment></React.Fragment>;
};

export { AppRoute };
