import { COMMENTS } from "../actionTypes";

export const getCommentsRequest = () => ({
    type: COMMENTS.FETCH_ALL_COMMENTS_REQUEST
})

export const getCommentsSuccess = payload => ({
    type: COMMENTS.FETCH_ALL_COMMENTS_SUCCESS,
    payload
})

export const getCommentsError = error => ({
    type: COMMENTS.FETCH_ALL_COMMENTS_ERROR,
    error
})

export const addCommentRequest = () => ({
    type: COMMENTS.ADD_COMMENT_REQUEST,
})

export const addCommentSuccess = payload => ({
    type: COMMENTS.ADD_COMMENT_SUCCESS,
    payload
})

export const addCommentError = error => ({
    type: COMMENTS.ADD_COMMENT_ERROR,
    error
})

export const deleteCommentRequest = () => ({
    type: COMMENTS.DELETE_COMMENT_REQUEST,
})

export const deleteCommentSuccess = payload => ({
    type: COMMENTS.DELETE_COMMENT_SUCCESS,
    payload
})

export const deleteCommentError = error => ({
    type: COMMENTS.DELETE_COMMENT_ERROR,
    error
})

export const editCommentRequest = () => ({
    type: COMMENTS.EDIT_COMMENT_REQUEST,
})

export const editCommentSuccess = payload => ({
    type: COMMENTS.EDIT_COMMENT_SUCCESS,
    payload
})

export const editCommentError = error => ({
    type: COMMENTS.EDIT_COMMENT_ERROR,
    error
})
