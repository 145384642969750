import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import './App.css'; 
import RoutesHOC from "./settings/routes/routes";
import history from "./settings/helpers/History";
import 'react-toastify/dist/ReactToastify.css';


function App() {
  return (
    <div className="App">
      <Router history={history}>
        <RoutesHOC />
      </Router>
        
    </div>
  );
}

export default App;
